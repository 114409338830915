<div id="tcqi-leftSide" class="mdl-cell--3-col tcqi--with-scroll">
  <div class="obres-content">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <itec-button
          class="itec-float-right"
          label="DEFAULT_TORNAR"
          ng-click-function="ctrl.back()"
          icon-left="ctrl.backButton">
        </itec-button>
      </div>
    </div>
  </div>
</div>
<div id="tcqi-content" class="mdl-cell--9-col tcqi--with-scroll">
  <div class="obres-content">
    <h5 class="mdl-layout-title mdl-cell--8-col">
      {{::'DEFAULT_DADES_OBRA' | translate}}
    </h5>
    <div class="mdl-cell mdl-cell--12-col">
      <itec-form
          name="ctrl.formulari.nom"
          fields="ctrl.formulari.camps"
          ng-model="ctrl.obra"
          ng-submit-function="ctrl.formulari.func()"
          errors="ctrl.errors"
          backbutton="false"
          submit-button="!ctrl.obra.teSeguiment">

        <div transclude-to="additional-form" >
          <div class="mdl-grid">
            <itec-input-text class="mdl-cell mdl-cell--5-col"
                             name="tipologiaEDC"
                             ng-model="ctrl.obra.tipologiaEDC.nom"
                             label="{{::'DEFAULT_TIPOLOGIA_EDC' | translate}}"
                             ng-required="false"
                             ng-disabled="true">
            </itec-input-text>
            <itec-button
              class="mdl-cell mdl-cell--1-col"
              ng-click-function="ctrl.selectTipologia()"
              class="icons-size"
              icon-left="{'class' : 'icon-llista'}"
              show-only-icon='true'
              css-classes="itec-button-squared">
            </itec-button>

          </div>
          <div class="mdl-grid" >
              <!-- tipus number -->
              <itec-input-numeric ng-form="form" ng-repeat="pregunta in ctrl.obra.listPreguntes"
                g-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Numeric"
                class="mdl-cell mdl-cell--6-col"
                ng-model="pregunta.valorNumeric"
                label="{{ctrl.getDescPregunta(pregunta)}}"
                decimal-places="ctrl.TcqihomeConstant.Decimals"
                ng-required="false">
              </itec-input-numeric>
              <!-- tipus text-->
              <itec-input-text
                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Cadena"
                class="mdl-cell mdl-cell--6-col"
                ng-model="pregunta.valorCadena"
                label="{{ctrl.getDescPregunta(pregunta)}}"
                ng-required="false">
              </itec-input-text>
              <!-- tipus si no-->
              <itec-checkbox
                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Boolea"
                class="mdl-cell mdl-cell--6-col"
                ng-model="pregunta.valorBoolea"
                label="{{ctrl.getDescPregunta(pregunta)}}"
                ng-required="false">
              </itec-checkbox>
          </div>
        </div>


      </itec-form>
    </div>
  </div>
  <div class="obres-content" ng-if="!ctrl.isNew">

    <h5 class="mdl-layout-title mdl-cell--8-col">
      {{::'DEFAULT_IMATGE' | translate}}
    </h5>
    <div class="mdl-cell mdl-cell--12-col">
      <md-card>
        <md-card-content>
          <tcq-imatge-form-upload
            select-files-text="SHARED_DOCUMENT_UPLOAD_SELECT_FILES"
            save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
            max-size="{{ctrl.maxUploadSize}}"
            max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
            show-only-icon="false"
            id-container="ctrl.obra.id"
            ng-model="ctrl.obra.imatge"
            icon-left="ctrl.iconLeft"
            server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_TCQIHOME}}"
            server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
            app-name="{{ctrl.ApiLayoutConstant.APP_NOM_TCQIHOME}}"
            auth-name="{{ctrl.TcqihomeConstant.AUTH_TCQIHOME}}"
            request-headers="ctrl.headers">
          </tcq-imatge-form-upload>
        </md-card-content>
      </md-card>
    </div>
  </div>
</div>
