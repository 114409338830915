(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.obres.filter:obraByCodiAndDescripcio
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres')
    .filter('obraByCodiAndDescripcio', obraByCodiAndDescripcioFilter);

  function obraByCodiAndDescripcioFilter() {
    return function (items, search) {
        var result = [];
        angular.forEach(items, function (value, key) {
          if (value.codi.indexOf(search) !== -1 || value.descripcio.indexOf(search) !== -1) {
              result.push(value);
          }
        });
        return result;

    }
  }
}());