(function () {
  'use strict';

  angular
    .module('itecTcqihome.obres.dades')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('obres.dades', {
        url: '/{idObra:[0-9]{0,32}}/dades',
        views: {
          'wrapper@': {
              templateUrl : 'obres/dades/dades.tpl.html',
              controller  : 'ObresDadesCtrl',
              controllerAs: 'ctrl'
          }
        },
        resolve: {
          obraData: function(SisquilloFactory, $stateParams) {
            if (!angular.isUndefined($stateParams.isNew) &&
                $stateParams.isNew !== null && $stateParams.isNew) {

                return {};
            }
            else if (!angular.isUndefined($stateParams.idObra) &&
                     $stateParams.idObra !== null && $stateParams.idObra) {

              return SisquilloFactory.get($stateParams.idObra).catch(function(){});
            }
            else {
              return {};
            }
          },
          dadesGeneralsData: function(SisquilloFactory) {
            return SisquilloFactory.getDadesGenerals().catch(function(){});
          },
          tipologies: function(SisquilloFactory) {
            return SisquilloFactory.getTipologies().catch(function(){});
          }
        }
      });
  }
}());
