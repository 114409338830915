(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome
   * @description
   *
   */
  angular
    .module('itecTcqihome', [
      'ui.router',
      'itecApi',
      'itecTcqihome.obres',
      'itecTcqihome.api',
      'itecTcqihome.shared',
      'itecLocale',
      'itecGuicomponents.layout',
      'itecGuicomponents.commonControls',
      'itecGuicomponents.commonTcq',
      'ngDraggable',
      'ngCookies',
      'ngFileSaver'
    ])
    .config(function ($translatePartialLoaderProvider, $urlRouterProvider, $httpProvider) {

       $httpProvider.defaults.useXDomain = true;

      $translatePartialLoaderProvider.addPart('resources');
      $translatePartialLoaderProvider.addPart('vendor/itec-guicomponents/dist/resources/common-controls/resources');
        
   })
    .config(function($httpProvider) {  
      $httpProvider.interceptors.push('LocaleInterceptorFactory');
    })
	 .run(function($rootScope, LocalStorage, SessionStorage, DataFactory, ApiLayoutConstant){

      //Configuració per itec-guicomponents
      LocalStorage.setBasePath('itecTcqihome');
      SessionStorage.setBasePath('itecTcqihome');
      $rootScope.prefixItecGuicomponents = 'vendor/itec-guicomponents/dist/';

      DataFactory.setApiParameters('tcqihome', ApiLayoutConstant.SERVER_VERSION_TCQIHOME, 'authtcqihome','');
    });
}());
