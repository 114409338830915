(function () {
  'use strict';

  /* @ngdoc object
   * @name shared
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared', [
      'itecTcqihome.shared.tables',
      'itecTcqihome.shared.pdfViewer',
      'itecTcqihome.shared.breadcrumb',
      'itecTcqihome.shared.headerSubmenu',
      'itecTcqihome.shared.submain',
      'itecTcqihome.shared.locale',
      'itecTcqihome.shared.tcqObraFormUpload',
      'itecTcqihome.shared.tcqBc3FormUpload'
    ]);
}());
