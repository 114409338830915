<div class="header dialog-toolbar-green">
  <md-button size="10" ng-click=ctrl.cancel() aria-label="Info"
             class=" icon-white no-margin-buttons float-right" >
    <i class="material-icons size-icons">close</i>
  </md-button>
</div>
<div>
  <itec-tree
    items="ctrl.tipologies"
    item-name="node"
    expanded-collection="ctrl.expanded"
    children="node.children"
    has-children="node.hasChildren"
    show-button="true"
    order-by="nom">
    <itec-checkbox ng-disabled="false"
                   ng-model="node.checked"
                   ng-click="ctrl.changeCheck(node)">
    </itec-checkbox>
    {{node.nom}}
  </itec-tree>
  <span style="float: right">
    <itec-button label="{{'DIALOG_CONFIRM' | translate}}" ng-click-function="ctrl.confirm()"></itec-button>
  </span>
</div>
