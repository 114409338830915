(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name itecTcqihome.controller:AppCtrl
     *
     * @description
     *
     */
    angular
      .module('itecTcqihome')
      .controller('AppCtrl', AppCtrl);

    function AppCtrl(theme, headerMenuLinks, headerMenuIconsPosition, $timeout, ApiLayoutConstant,
        DataFactory, $location, $scope, AppWrapperFactory, $rootScope, LogoutFactory, PermisosFactory,
        LocaleService) {
      var vm = this;

      $rootScope.modul = theme;

      ApiLayoutConstant.SERVER_PATH_MANTENIMENT_COMPLETE_API =
        'https://'+$location.$$host+'/api/manteniment/';
      ApiLayoutConstant.SERVER_ROOT_PATH = 'https://'+$location.$$host+'/';
      ApiLayoutConstant.SERVER_PATH_MANTENIMENT_REPORTS_API =
        'https://'+$location.$$host+'/api/reportsmanteniment/b2b/';


      vm.app = {};
      vm.app.theme = theme;
      vm.app.headerMenuLinks = headerMenuLinks;
      vm.app.headerMenuIconsPosition = headerMenuIconsPosition;

      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'permisos').then(function(response){
        $scope.$apply(function(){
          $rootScope.permisos = response.data.permisos;
          $rootScope.usuari = response.data.usuari;
          LocaleService.setLocaleByIdioma($rootScope.usuari.idioma);
        });
      });

      vm.accesosAppsTCQi = undefined;
      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(function(response) {
        vm.accesosAppsTCQi = response.data.acces;
        $rootScope.acces = response.data.acces;
      }).catch(function(){});

      AppWrapperFactory.init($scope);


      vm.loaded = false;
      $scope.$on('$viewContentLoaded', function(ev, data){
        $timeout(function() {
          vm.loaded = true;
          $rootScope.$broadcast('tcqiAppLoaded');
        },0);
      });

      vm.logout=logout;

      function logout() {
        var promise = LogoutFactory.get().then(function(response){

        }).catch(function(error){

        });
        return promise;
      }
    }
}());
