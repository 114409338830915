(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome
   * @description
   *
   */
  angular
    .module('itecTcqihome', [
      'ui.router',
      'itecApi',
      'itecTcqihome.obres',
      'itecTcqihome.api',
      'itecTcqihome.shared',
      'itecLocale',
      'itecGuicomponents.layout',
      'itecGuicomponents.commonControls',
      'itecGuicomponents.commonTcq',
      'ngDraggable',
      'ngCookies',
      'ngFileSaver'
    ])
    .config(["$translatePartialLoaderProvider", "$urlRouterProvider", "$httpProvider", function ($translatePartialLoaderProvider, $urlRouterProvider, $httpProvider) {

       $httpProvider.defaults.useXDomain = true;

      $translatePartialLoaderProvider.addPart('resources');
      $translatePartialLoaderProvider.addPart('vendor/itec-guicomponents/dist/resources/common-controls/resources');
        
   }])
    .config(["$httpProvider", function($httpProvider) {  
      $httpProvider.interceptors.push('LocaleInterceptorFactory');
    }])
	 .run(["$rootScope", "LocalStorage", "SessionStorage", "DataFactory", "ApiLayoutConstant", function($rootScope, LocalStorage, SessionStorage, DataFactory, ApiLayoutConstant){

      //Configuració per itec-guicomponents
      LocalStorage.setBasePath('itecTcqihome');
      SessionStorage.setBasePath('itecTcqihome');
      $rootScope.prefixItecGuicomponents = 'vendor/itec-guicomponents/dist/';

      DataFactory.setApiParameters('tcqihome', ApiLayoutConstant.SERVER_VERSION_TCQIHOME, 'authtcqihome','');
    }]);
}());

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/tcq-obra-form-upload/tcq-obra-form-upload-directive.tpl.html',
    '<div class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '  <itec-form-file-upload\n' +
    '    name="form"\n' +
    '    class="mdl-cell mdl-cell--12-col"\n' +
    '    button-class="itec-float-right"\n' +
    '    files="vm.files"\n' +
    '    err-files="vm.errFiles"\n' +
    '    funcio-seleccionar="vm.selectFile()"\n' +
    '    select-files-text="{{selectFilesText}}"\n' +
    '    max-size="{{vm.maxSize}}"\n' +
    '    pattern="[\'.tcqcsv\', \'.zip\']"\n' +
    '    show-only-icon="true"\n' +
    '    icon-left="vm.iconLeft">\n' +
    '  </itec-form-file-upload>\n' +
    '\n' +
    '  <!--<div class="mdl-cell mdl-cell--12-col" ng-show="vm.objFile.status == \'uploading\' || vm.objFile.status == \'done\'">\n' +
    '    {{vm.objFile.file.name}}\n' +
    '    <md-button ng-show="vm.objFile.progress==100" class="md-icon-button">\n' +
    '      <md-icon>done</md-icon>\n' +
    '    </md-button>\n' +
    '    <span flex></span>\n' +
    '    <md-progress-linear md-mode="determinate" value="{{vm.objFile.progress}}">\n' +
    '    </md-progress-linear>\n' +
    '  </div>-->\n' +
    '\n' +
    '  <itec-form-file-upload-progress ng-if="vm.objFile !== undefined" file="vm.objFile"></itec-form-file-upload-progress>\n' +
    '\n' +
    '  <itec-form-file-upload-errors\n' +
    '    err-files="vm.errFiles"\n' +
    '    max-size="{{vm.maxSize}}"\n' +
    '    max-size-exceeded-text={{maxSizeExceededText}}>\n' +
    '  </itec-form-file-upload-errors>\n' +
    '\n' +
    '  <itec-error-block \n' +
    '    errors="vm.errors[\'\']" \n' +
    '    class="validation-messages mdl-cell mdl-cell--12-col">\n' +
    '  </itec-error-block>\n' +
    '\n' +
    '</div>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/tcq-bc3-form-upload/tcq-bc3-form-upload-directive.tpl.html',
    '<div class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '  <itec-form-file-upload\n' +
    '    name="form"\n' +
    '    class="mdl-cell mdl-cell--12-col"\n' +
    '    button-class="itec-float-right"\n' +
    '    files="vm.files"\n' +
    '    err-files="vm.errFiles"\n' +
    '    funcio-seleccionar="vm.selectFile()"\n' +
    '    select-files-text="{{selectFilesText}}"\n' +
    '    max-size="{{vm.maxSize}}"\n' +
    '    pattern="[\'.bc3\', \'.zip\']"\n' +
    '    icon-left="vm.iconLeft">\n' +
    '  </itec-form-file-upload>\n' +
    '\n' +
    '  <!--<div class="mdl-cell mdl-cell--12-col" ng-show="vm.objFile.status == \'uploading\' || vm.objFile.status == \'done\'">\n' +
    '    {{vm.objFile.file.name}}\n' +
    '    <md-button ng-show="vm.objFile.progress==100" class="md-icon-button">\n' +
    '      <md-icon>done</md-icon>\n' +
    '    </md-button>\n' +
    '    <span flex></span>\n' +
    '    <md-progress-linear md-mode="determinate" value="{{vm.objFile.progress}}">\n' +
    '    </md-progress-linear>\n' +
    '  </div>-->\n' +
    '\n' +
    '  <itec-form-file-upload-progress ng-if="vm.objFile !== undefined" file="vm.objFile"></itec-form-file-upload-progress>\n' +
    '\n' +
    '  <itec-form-file-upload-errors\n' +
    '    err-files="vm.errFiles"\n' +
    '    max-size="{{vm.maxSize}}"\n' +
    '    max-size-exceeded-text={{maxSizeExceededText}}>\n' +
    '  </itec-form-file-upload-errors>\n' +
    '\n' +
    '  <itec-error-block \n' +
    '    errors="vm.errors[\'\']" \n' +
    '    class="validation-messages mdl-cell mdl-cell--12-col">\n' +
    '  </itec-error-block>\n' +
    '\n' +
    '</div>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/submain/submain.tpl.html',
    '<div ui-view="content"></div>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/pdf-viewer/mnt-pdf-viewer-directive.tpl.html',
    '<div class="mdl-grid mdl-grid--no-spacing">\n' +
    '  <div class="mdl-cell mdl-cell--12-col">\n' +
    '    <form name="formulari" novalidate>\n' +
    '      <div id="itec-left-filter-pdf" class="mdl-grid mdl-grid--no-spacing">\n' +
    '        <div id="buscador" ng-repeat="group in fields" ng-if="ctrl.expandedSearch" class="mdl-cell mdl-cell--12-col group-div">\n' +
    '          <div class="mdl-grid">\n' +
    '            <div class="mdl-cell mdl-cell--{{group.columns ? group.columns : 12}}-col">\n' +
    '              <div class="mdl-grid" ng-hide="group.collapsed">\n' +
    '\n' +
    '                <div ng-repeat="field in group.camps" class="mdl-cell mdl-cell--12-col">\n' +
    '                  <div ng-include="\'common-controls/directives/itec-form-fields.tpl.html\'"></div>\n' +
    '                  <div ng-if="field.type === \'multiTransclude\'" ng-transclude transclude-from="{{field.block}}" name="{{field.name}}"></div> \n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="itec-content-filter-pdf" class="mdl-cell mdl-cell--12-col">\n' +
    '    <form id="pdfButtons">\n' +
    '      <itec-pdf-buttons\n' +
    '        pre-function="preFunction()"\n' +
    '        go-pdf-url="ctrl.goPdfUrl()"\n' +
    '        doc-url="ctrl.pdfUrl"\n' +
    '        download-doc="ctrl.downloadDoc()"\n' +
    '        download-xls="ctrl.downloadXls()"\n' +
    '        doc-button="docButton"\n' +
    '        xls-button="xlsButton"\n' +
    '        go-transclude="ctrl.goTransclude()"\n' +
    '        transclude-button="ctrl.havingTranscludeOp"\n' +
    '        transclude-text="{{transcludeButtonText}}"\n' +
    '        >\n' +
    '      </itec-pdf-buttons>\n' +
    '    </form>\n' +
    '    <div ng-show="ctrl.showPdfLayout">\n' +
    '      <iframe type="text/html" ng-src="{{trustSrc(ctrl.pdfUrl)}}" style="height: 900px;" class="mdl-cell mdl-cell--12-col"></iframe>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/header-submenu/headerSubmenu.tpl.html',
    '<tcqi-header-submenu ng-if="vm.attr.header.submenu.show" attr="vm.attr"></tcqi-header-submenu>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/exportar-a-fie/importar-de-fie-dialog.tpl.html',
    '<md-dialog  aria-label="{{\'DEFAULT_EXPORTAR_FIE\' | translate}}">\n' +
    '  <form>\n' +
    '    <md-toolbar>\n' +
    '      <div class="md-toolbar-tools">\n' +
    '        <h2>{{\'DEFAULT_IMPORTAR_FIE\' | translate}}</h2>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-icon-button" ng-click="hide()">\n' +
    '          <md-icon aria-label="{{\'DEFAULT_TANCAR\' | translate}}">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content>\n' +
    '      <div class="md-dialog-content">\n' +
    '        <div class="mdl-grid">\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '          <itec-error-block errors="ctrl.errors[\'\']"></itec-error-block>\n' +
    '        </div>\n' +
    '    \n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        <itec-input-text\n' +
    '          name="codi"\n' +
    '          ng-model="ctrl.codi"\n' +
    '          label="{{\'DEFAULT_CODI\' | translate}}"\n' +
    '          ng-required="true"\n' +
    '	      ng-minlength="1"\n' +
    '	      ng-maxlength="50"\n' +
    '          validation-message-required="{{::\'DEFAULT_VALIDATION_MESSAGE_REQUIRED\'}}"\n' +
    '          validation-message-maxlength="{{::\'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH\'}}" >\n' +
    '        </itec-input-text>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        <itec-input-text\n' +
    '          name="codi"\n' +
    '          ng-model="ctrl.descripcio"\n' +
    '          label="{{\'DEFAULT_DESCRIPCIO\' | translate}}"\n' +
    '          ng-required="true"\n' +
    '	      ng-minlength="1"\n' +
    '	      ng-maxlength="2000"\n' +
    '          validation-message-required="{{::\'DEFAULT_VALIDATION_MESSAGE_REQUIRED\'}}"\n' +
    '          validation-message-maxlength="{{::\'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH\'}}" >\n' +
    '        </itec-input-text>\n' +
    '        </div>\n' +
    '        \n' +
    '      </div>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">\n' +
    '       {{\'DEFAULT_CANCELAR\' | translate}}\n' +
    '      </md-button>\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">\n' +
    '        {{\'DEFAULT_ACCEPTAR\' | translate}}\n' +
    '      </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '  </form>\n' +
    '</md-dialog>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/exportar-a-fie/exportar-a-fie-dialog.tpl.html',
    '<md-dialog  aria-label="{{\'DEFAULT_EXPORTAR_FIE\' | translate}}">\n' +
    '  <form>\n' +
    '    <md-toolbar>\n' +
    '      <div class="md-toolbar-tools">\n' +
    '        <h2>{{\'DEFAULT_EXPORTAR_FIE\' | translate}}</h2>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-icon-button" ng-click="hide()">\n' +
    '          <md-icon aria-label="{{\'DEFAULT_TANCAR\' | translate}}">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content>\n' +
    '      <div class="md-dialog-content">\n' +
    '        <div class="mdl-grid">\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '          <itec-error-block errors="ctrl.errors[\'\']"></itec-error-block>\n' +
    '        </div>\n' +
    '    \n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '		<itec-checkbox\n' +
    '			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '     		ng-model="ctrl.export_plecs">\n' +
    '		</itec-checkbox>\n' +
    '		{{\'DEFAULT_EXPORT_FIE_PLECS\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '		<itec-checkbox\n' +
    '			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '     		ng-model="ctrl.export_grafics">\n' +
    '		</itec-checkbox>\n' +
    '		{{\'DEFAULT_EXPORT_FIE_GRAFICS\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '		<itec-checkbox\n' +
    '			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '     		ng-model="ctrl.export_amid">\n' +
    '		</itec-checkbox>\n' +
    '		{{\'DEFAULT_EXPORT_FIE_AMID\' | translate }}\n' +
    '        </div>\n' +
    '        \n' +
    '      </div>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">\n' +
    '       {{\'DEFAULT_CANCELAR\' | translate}}\n' +
    '      </md-button>\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">\n' +
    '        {{\'DEFAULT_ACCEPTAR\' | translate}}\n' +
    '      </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '  </form>\n' +
    '</md-dialog>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/dades/tipologies.tpl.html',
    '<div class="header dialog-toolbar-green">\n' +
    '  <md-button size="10" ng-click=ctrl.cancel() aria-label="Info"\n' +
    '             class=" icon-white no-margin-buttons float-right" >\n' +
    '    <i class="material-icons size-icons">close</i>\n' +
    '  </md-button>\n' +
    '</div>\n' +
    '<div>\n' +
    '  <itec-tree\n' +
    '    items="ctrl.tipologies"\n' +
    '    item-name="node"\n' +
    '    expanded-collection="ctrl.expanded"\n' +
    '    children="node.children"\n' +
    '    has-children="node.hasChildren"\n' +
    '    show-button="true"\n' +
    '    order-by="nom">\n' +
    '    <itec-checkbox ng-disabled="false"\n' +
    '                   ng-model="node.checked"\n' +
    '                   ng-click="ctrl.changeCheck(node)">\n' +
    '    </itec-checkbox>\n' +
    '    {{node.nom}}\n' +
    '  </itec-tree>\n' +
    '  <span style="float: right">\n' +
    '    <itec-button label="{{\'DIALOG_CONFIRM\' | translate}}" ng-click-function="ctrl.confirm()"></itec-button>\n' +
    '  </span>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/dades/dades.tpl.html',
    '<div id="tcqi-leftSide" class="mdl-cell--3-col tcqi--with-scroll">\n' +
    '  <div class="obres-content">\n' +
    '    <div class="mdl-grid">\n' +
    '      <div class="mdl-cell mdl-cell--12-col">\n' +
    '        <itec-button\n' +
    '          class="itec-float-right"\n' +
    '          label="DEFAULT_TORNAR"\n' +
    '          ng-click-function="ctrl.back()"\n' +
    '          icon-left="ctrl.backButton">\n' +
    '        </itec-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div id="tcqi-content" class="mdl-cell--9-col tcqi--with-scroll">\n' +
    '  <div class="obres-content">\n' +
    '    <h5 class="mdl-layout-title mdl-cell--8-col">\n' +
    '      {{::\'DEFAULT_DADES_OBRA\' | translate}}\n' +
    '    </h5>\n' +
    '    <div class="mdl-cell mdl-cell--12-col">\n' +
    '      <itec-form\n' +
    '          name="ctrl.formulari.nom"\n' +
    '          fields="ctrl.formulari.camps"\n' +
    '          ng-model="ctrl.obra"\n' +
    '          ng-submit-function="ctrl.formulari.func()"\n' +
    '          errors="ctrl.errors"\n' +
    '          backbutton="false"\n' +
    '          submit-button="!ctrl.obra.teSeguiment">\n' +
    '\n' +
    '        <div transclude-to="additional-form" >\n' +
    '          <div class="mdl-grid">\n' +
    '            <itec-input-text class="mdl-cell mdl-cell--5-col"\n' +
    '                             name="tipologiaEDC"\n' +
    '                             ng-model="ctrl.obra.tipologiaEDC.nom"\n' +
    '                             label="{{::\'DEFAULT_TIPOLOGIA_EDC\' | translate}}"\n' +
    '                             ng-required="false"\n' +
    '                             ng-disabled="true">\n' +
    '            </itec-input-text>\n' +
    '            <itec-button\n' +
    '              class="mdl-cell mdl-cell--1-col"\n' +
    '              ng-click-function="ctrl.selectTipologia()"\n' +
    '              class="icons-size"\n' +
    '              icon-left="{\'class\' : \'icon-llista\'}"\n' +
    '              show-only-icon=\'true\'\n' +
    '              css-classes="itec-button-squared">\n' +
    '            </itec-button>\n' +
    '\n' +
    '          </div>\n' +
    '          <div class="mdl-grid" >\n' +
    '              <!-- tipus number -->\n' +
    '              <itec-input-numeric ng-form="form" ng-repeat="pregunta in ctrl.obra.listPreguntes"\n' +
    '                g-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Numeric"\n' +
    '                class="mdl-cell mdl-cell--6-col"\n' +
    '                ng-model="pregunta.valorNumeric"\n' +
    '                label="{{ctrl.getDescPregunta(pregunta)}}"\n' +
    '                decimal-places="ctrl.TcqihomeConstant.Decimals"\n' +
    '                ng-required="false">\n' +
    '              </itec-input-numeric>\n' +
    '              <!-- tipus text-->\n' +
    '              <itec-input-text\n' +
    '                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Cadena"\n' +
    '                class="mdl-cell mdl-cell--6-col"\n' +
    '                ng-model="pregunta.valorCadena"\n' +
    '                label="{{ctrl.getDescPregunta(pregunta)}}"\n' +
    '                ng-required="false">\n' +
    '              </itec-input-text>\n' +
    '              <!-- tipus si no-->\n' +
    '              <itec-checkbox\n' +
    '                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Boolea"\n' +
    '                class="mdl-cell mdl-cell--6-col"\n' +
    '                ng-model="pregunta.valorBoolea"\n' +
    '                label="{{ctrl.getDescPregunta(pregunta)}}"\n' +
    '                ng-required="false">\n' +
    '              </itec-checkbox>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '      </itec-form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="obres-content" ng-if="!ctrl.isNew">\n' +
    '\n' +
    '    <h5 class="mdl-layout-title mdl-cell--8-col">\n' +
    '      {{::\'DEFAULT_IMATGE\' | translate}}\n' +
    '    </h5>\n' +
    '    <div class="mdl-cell mdl-cell--12-col">\n' +
    '      <md-card>\n' +
    '        <md-card-content>\n' +
    '          <tcq-imatge-form-upload\n' +
    '            select-files-text="SHARED_DOCUMENT_UPLOAD_SELECT_FILES"\n' +
    '            save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '            max-size="{{ctrl.maxUploadSize}}"\n' +
    '            max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '            show-only-icon="false"\n' +
    '            id-container="ctrl.obra.id"\n' +
    '            ng-model="ctrl.obra.imatge"\n' +
    '            icon-left="ctrl.iconLeft"\n' +
    '            server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_TCQIHOME}}"\n' +
    '            server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '            app-name="{{ctrl.ApiLayoutConstant.APP_NOM_TCQIHOME}}"\n' +
    '            auth-name="{{ctrl.TcqihomeConstant.AUTH_TCQIHOME}}"\n' +
    '            request-headers="ctrl.headers">\n' +
    '          </tcq-imatge-form-upload>\n' +
    '        </md-card-content>\n' +
    '      </md-card>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/notifications-template-tpl.html',
    '<div  class="contractes-content">\n' +
    '  <div class="mdl-grid mdl-grid--no-spacing">\n' +
    '    <div class="mdl-cell mdl-cell--12-col">\n' +
    '      <md-card>\n' +
    '        <md-card-title>{{title | translate}}</md-card-title>\n' +
    '        <md-card-content>\n' +
    '          <table class="itec-table">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th>{{ \'DEFAULT_TIPUS_MANTENIMENT\'  | translate }}</th>\n' +
    '                <th>{{ \'DEFAULT_DESCRIPCIO\'         | translate }}</th>\n' +
    '                <th>{{ \'DEFAULT_ACCIONS\'            | translate }}</th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="row in notificationsDades" class="{{row.llegit?\'\':\'bold\'}}">\n' +
    '                <td>\n' +
    '                  <span ng-if="!angular.isUndefined(row.tipusMant)">\n' +
    '                    {{row.tipusMant}}<br/>\n' +
    '                  </span>\n' +
    '                  {{row.dmy}}\n' +
    '                </td>\n' +
    '              <td>\n' +
    '                <pre>\n' +
    '                  {{row.descripcio}}\n' +
    '                </pre>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <button ng-if="!row.llegit" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored" ng-click="notificationsFunctions[0](row.id)">\n' +
    '                  <i class="material-icons">markunread</i>\n' +
    '                  <!--_OK_READ_ACTION_-->\n' +
    '                </button>\n' +
    '                <button ng-if="row.llegit" ng-click="notificationsFunctions[1](row.id)" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored">\n' +
    '                <i class="material-icons">delete</i>\n' +
    '                <!--_OK_DELETE_MESSAGE_ACTION_-->\n' +
    '                </button>\n' +
    '                <a  ng-if="row.fileName !== null" \n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored" \n' +
    '                    href="{{notificationsFunctions[2](row.id,row.fileName)}}" \n' +
    '                    target="_self">\n' +
    '                  <i class="material-icons">file_download</i>\n' +
    '                  <!--_OK_DOWNLOAD_MESSAGE_ANNEX_-->\n' +
    '                </a>\n' +
    '              </td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </md-card-content>\n' +
    '      </md-card>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/obres.tpl.html',
    '<div id="tcqi-content" class="mdl-cell--12-col tcqi--with-scroll mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '  <!-- Botones -->\n' +
    '  <div id="tcqihome--obres-buttons" class="mdl-cell--12-col tcqi--margin-top-20">\n' +
    '    <div class="tcqihome--obres-title">\n' +
    '      <i class="material-icons">folder</i>\n' +
    '      <span class="bold">{{::\'OBRES_TOTES_OBRES\' | translate}}</span>\n' +
    '    </div>\n' +
    '\n' +
    '    <button class="tcqi--margin-left-30 tcqi--margin-right-15 itec-float-right mdl-button mdl-js-button mdl-button--icon\n' +
    '        mdl-button--colored" ng-click="ctrl.listMode = !ctrl.listMode" ng-if="ctrl.obres.length > 0">\n' +
    '\n' +
    '      <i ng-if="ctrl.listMode" class="icon-th-large" style="font-size: large;"\n' +
    '         title="{{::\'DEFAULT_ACCIONS_VISUALITZAR_ICONES\' | translate}}"\n' +
    '         aria-label="{{::\'DEFAULT_ACCIONS_VISUALITZAR_ICONES\' | translate}}">\n' +
    '       \n' +
    '      </i>\n' +
    '      <i ng-if="!ctrl.listMode" class="material-icons"\n' +
    '         title="{{::\'DEFAULT_ACCIONS_VISUALITZAR_LLISTA\' | translate}}"\n' +
    '         aria-label="{{::\'DEFAULT_ACCIONS_VISUALITZAR_LLISTA\' | translate}}">\n' +
    '        format_list_bulleted\n' +
    '      </i>\n' +
    '    </button>\n' +
    '\n' +
    '    <tcq-bc3-form-upload\n' +
    '      select-files-text="DEFAULT_IMPORTAR_FIE"\n' +
    '      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '      max-size="{{ctrl.TcqiHomeConstant.UPLOAD_OBRA_MAX_SIZE}}"\n' +
    '      show-only-icon="false"\n' +
    '      on-finish-function="ctrl.viewDades"\n' +
    '      icon-left="ctrl.iconLeft"\n' +
    '      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"\n' +
    '      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '      class="itec-float-right">\n' +
    '    </tcq-bc3-form-upload>\n' +
    '\n' +
    '    <tcq-obra-form-upload\n' +
    '      select-files-text="DEFAULT_IMPORTAR_TCQI"\n' +
    '      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '      max-size="10MB"\n' +
    '      show-only-icon="false"\n' +
    '      on-finish-function="ctrl.viewDades"\n' +
    '      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"\n' +
    '      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '      class="itec-float-right">\n' +
    '    </tcq-obra-form-upload>\n' +
    '\n' +
    '    <itec-button class="itec-float-right tcqi--margin-left-20" label="DEFAULT_NOU"\n' +
    '                 ng-click-function="ctrl.afegir()" icon-left="ctrl.addButton">\n' +
    '    </itec-button>\n' +
    '\n' +
    '\n' +
    '    <form ng-if="ctrl.obres.length > 0" class="itec-float-right">\n' +
    '      <div ng-show="ctrl.expanded" class="tcqihome-search itec-float-right mdl-textfield mdl-js-textfield">\n' +
    '        <input class="mdl-textfield__input" type="text" id="search-expandable" ng-model="ctrl.searchText" />\n' +
    '        <label class="mdl-textfield__label" for="search-expandable">\n' +
    '          {{::\'DEFAULT_CERCA_CODI_O_TITOL\' | translate}}\n' +
    '        </label>\n' +
    '      </div>\n' +
    '\n' +
    '      <label class="itec-float-right mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '             ng-click="ctrl.expanded=!ctrl.expanded">\n' +
    '        <i class="material-icons" title="{{::\'DEFAULT_CERCAR\' | translate}}"\n' +
    '            aria-label="{{::\'DEFAULT_CERCAR\' | translate}}">search</i>\n' +
    '      </label>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <div ng-if="ctrl.obres.length === 0" id="tcqihome--obres-no-items">\n' +
    '    <span>{{::\'DEFAULT_NO_ITEM\' | translate}}</span>\n' +
    '    <div class="clear"></div>\n' +
    '\n' +
    '    <!-- Aplicaciones "TCQi" -->\n' +
    '    <div class="tcqihome--obres-title"><i class="icon-icono-grups-blanc"></i><span class="bold">MÒDULS</span></div>\n' +
    '    <div class="tcqi-table mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqi--margin-top-20">\n' +
    '      <div ng-repeat="tcqiApp in ctrl.tcqiApps" class="tcqihome--obres-apps mdl-cell--2-col">\n' +
    '        <div>\n' +
    '          <a ng-click="ctrl.openObra(undefined, tcqiApp.app)">\n' +
    '            <img ng-src="{{tcqiApp.image}}"><br>\n' +
    '            <span class="itec-float-left tcqi--margin-top-10">\n' +
    '              {{::tcqiApp.title | translate | uppercase}}\n' +
    '            </span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="clear"></div>\n' +
    '        <span>{{::tcqiApp.descr}}</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <!-- Listado de obras -->\n' +
    '  <div ng-if="ctrl.obres.length > 0" id="tcqihome--obres-list" class="tcqi-table mdl-cell--12-col">\n' +
    '\n' +
    '\n' +
    '    <!-- Cabecera -->\n' +
    '    <div ng-if="ctrl.listMode" class="tcqi--table-header mdl-grid mdl-grid--no-spacing">\n' +
    '      <div class="mdl-cell--1-col">\n' +
    '        <a ng-click="ctrl.sortType = \'codi\'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">\n' +
    '          <span>{{::\'DEFAULT_CODI\' | translate}}</span>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'codi\' && !ctrl.sortReverse" class="icon-caret-down"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'codi\'" class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'codi\' && ctrl.sortReverse" class="icon-caret-up"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'codi\'" class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-cell--5-col">\n' +
    '        <a ng-click="ctrl.sortType = \'descripcio\'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">\n' +
    '          <span>{{::\'DEFAULT_TITOL\' | translate}}</span>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'descripcio\' && !ctrl.sortReverse" class="icon-caret-down"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'descripcio\'" class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'descripcio\' && ctrl.sortReverse" class="icon-caret-up"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'descripcio\'" class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-cell--1-col">\n' +
    '        <a ng-click="ctrl.sortType = \'dataCreacio\'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">\n' +
    '          {{::\'DEFAULT_DATA\' | translate}}\n' +
    '\n' +
    '          <span ng-show="ctrl.sortType === \'dataCreacio\' && !ctrl.sortReverse" class="icon-caret-down"></span>\n' +
    '          <span ng-show="ctrl.sortType !== \'dataCreacio\'" class="icon-caret-down tcqi-two-icon-carets"></span>\n' +
    '\n' +
    '          <span ng-show="ctrl.sortType === \'dataCreacio\' && ctrl.sortReverse" class="icon-caret-up"></span>\n' +
    '          <span ng-show="ctrl.sortType !== \'dataCreacio\'" class="icon-caret-up tcqi-two-icon-carets"></span>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-cell--3-col"><span>{{::\'DEFAULT_APLICACIONS\' | translate}}</span></div>\n' +
    '      <div class="mdl-cell--2-col"><span>{{::\'DEFAULT_ACCIONS\' | translate}}</span></div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <!-- Ítems. Formato estándar -->\n' +
    '    <div ng-if="ctrl.listMode" class="tcqi--table-tbody">\n' +
    '      <div ng-repeat="obra in ctrl.obres | obraByCodiAndDescripcio:ctrl.searchText |\n' +
    '                      orderBy:ctrl.sortType:ctrl.sortReverse" class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '        <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"\n' +
    '            ng-click="ctrl.viewDades(obra.id)" title="{{::obra.codi}}">\n' +
    '          <span class="tcqi--padding-left-15">{{::obra.codi}}</span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--5-col tcqi--ellipsis" title="{{::obra.descripcio}}"\n' +
    '            ng-click="ctrl.viewDades(obra.id)">\n' +
    '          <span class="tcqi--padding-left-15">{{::obra.descripcio}}</span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha" ng-click="ctrl.viewDades(obra.id)">\n' +
    '          <span class="tcqi--padding-left-15">{{::obra.dataCreacio | date: \'itecDate\'}}</span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--3-col tcqihome--obres-table-tbody-td-apps">\n' +
    '          <div class="tcqi--padding-left-15">\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pre-color"\n' +
    '                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PRESSUPOST)"\n' +
    '                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces">\n' +
    '            <i class="icon-tcqi-icona-pre"></i>\n' +
    '          </button>\n' +
    '\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gcq-color"\n' +
    '                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_QUALITAT)"\n' +
    '                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces">\n' +
    '            <i class="icon-tcqi-icona-gcq"></i>\n' +
    '          </button>\n' +
    '\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-seg-color"\n' +
    '                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGURETATISALUT)"\n' +
    '                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces">\n' +
    '            <i class="icon-tcqi-icona-ess"></i>\n' +
    '          </button>\n' +
    '\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"\n' +
    '                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT)"\n' +
    '                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces ">\n' +
    '            <i class="icon-tcqi-icona-gma"></i>\n' +
    '          </button>\n' +
    '\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pla-color"\n' +
    '                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PLANIFICACIO)"\n' +
    '                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces">\n' +
    '            <i class="icon-tcqi-icona-pla"></i>\n' +
    '          </button>\n' +
    '\n' +
    '\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-seg-color"\n' +
    '                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC)"\n' +
    '                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces">\n' +
    '            <i class="icon-tcqi-icona-seg"></i>\n' +
    '          </button>\n' +
    '\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-cdc-color"\n' +
    '                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_CONTROLCOSTOS)"\n' +
    '                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces">\n' +
    '            <i class="icon-tcqi-icona-gcc"></i>\n' +
    '          </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-acciones">\n' +
    '          <div class="tcqi--padding-left-15">\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '              title="{{\'DEFAULT_DADES_GENERALS\' | translate}}"\n' +
    '              ng-click="ctrl.viewDades(obra.id)">\n' +
    '            <i class="material-icons">description</i>\n' +
    '          </button>\n' +
    '\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                  title="{{\'DEFAULT_ESBORRAR_OBRA\' | translate}}"\n' +
    '                  itec-dialog-confirm\n' +
    '                  itec-dialog-confirm-title="OBRES_DELETE_TITLE"\n' +
    '                  itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT"\n' +
    '                  itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"\n' +
    '                  itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"\n' +
    '                  itec-dialog-confirm-function-ok="ctrl.esborrar(obra.id)">\n' +
    '            <i class="material-icons">delete_forever</i>\n' +
    '          </button>\n' +
    '\n' +
    '          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                  title="{{\'DEFAULT_EXPORTAR\' | translate}}"\n' +
    '                  ng-click="ctrl.exportarObra(obra.id)">\n' +
    '            <i class="icon-home-export-tcqi"></i>\n' +
    '          </button>\n' +
    '\n' +
    '          <button id="fie" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                  title="{{\'DEFAULT_EXPORTAR_FIE\' | translate}}"\n' +
    '                  ng-click="ctrl.exportarObraFIE(obra.id)">\n' +
    '             <!--<i class="icon-android-upload"></i>-->\n' +
    '             <i class="icon-home-export-fie"></i>\n' +
    '          </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <!-- Ítems. Formato cuadrícula -->\n' +
    '    <div ng-if="!ctrl.listMode" class="mdl-grid mdl-grid--no-spacing">\n' +
    '      <div ng-repeat="obra in ctrl.obres | obraByCodiAndDescripcio:ctrl.searchText"\n' +
    '          class="mdl-cell mdl-cell--3-col tcqihome-gallery-container">\n' +
    '        <div class="tcqihome-gallery-element">\n' +
    '          <div class="tcqihome-gallery-imatge">\n' +
    '            <span class="tcqihome-gallery-imatge-helper"></span><img ng-if="obra.imatge"\n' +
    '              data-ng-src="data:image/png;base64,{{obra.imatge.data}}" ng-click="ctrl.viewDades(obra.id)">\n' +
    '\n' +
    '            <img ng-if="!obra.imatge" class="ng-thumb"\n' +
    '                 ng-src="{{$root.prefixItecGuicomponents}}images/common-controls/views/images/icoimg.gif"\n' +
    '                 ng-click="ctrl.viewDades(obra.id)">\n' +
    '          </div>\n' +
    '\n' +
    '          <div><span>{{obra.descripcio}}</span></div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('ok.html',
    '0');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('clipboard.html',
    '<!DOCTYPE html>\n' +
    '<html>\n' +
    '<head>\n' +
    '    <script src="//metabase.itec.cat/vide/js/tools/crossDomainLocalStorage.js"></script>\n' +
    '</head>\n' +
    '<body>\n' +
    '</body>\n' +
    '</html>');
}]);

(function () {
  'use strict';

  /* @ngdoc object
   * @name commonControls.utils.tcq-obra-form-upload
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.tcqObraFormUpload', [
      'ngFileUpload'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.tcqObraFormUpload.directive:tcqObraFormUpload
   * @restrict EA
   *
   */
  tcqObraFormUpload.$inject = ["$cookies"];
  angular
    .module('itecTcqihome.shared.tcqObraFormUpload')
    .directive('tcqObraFormUpload', tcqObraFormUpload);

  function tcqObraFormUpload($cookies) {
    return {
      restrict: 'EA',
      scope: {
        chunkSize: '@?',
        selectFilesText: '@?',
        saveFilesText: '@',
        maxSize: '@?',
        maxSizeExceededText: '@',
        idTypeDocument: '=',
        listCategories: '=',
        onFinishFunction: '&?',
        serverPath: '@',
        serverVersion: '@'
      },
      templateUrl: 'shared/tcq-obra-form-upload/tcq-obra-form-upload-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: ["$scope", "$rootScope", "Notification", "HelperFactory", "UploadFactory", "UploadFileConstants", function ($scope, $rootScope, Notification, HelperFactory, UploadFactory, UploadFileConstants) {
        var vm = this;
        vm.name = 'tcqObraFormUpload';

        vm.uploadFile       = uploadFile;
        vm.selectFile       = selectFile;

        vm.size = 'min';
        vm.files = [];
        vm.errFiles = [];
        vm.errors = [];

        vm.chunkSize = angular.isUndefined($scope.chunkSize) ? UploadFileConstants.CHUNKSIZE : $scope.chunkSize;
        vm.maxSize = angular.isUndefined($scope.maxSize) ? UploadFileConstants.MAX_SIZE_UPLOAD_DEFAULT : $scope.maxSize;

        vm.onFinishFunction = $scope.onFinishFunction;

        vm.iconLeft = {};
        vm.iconLeft.class = 'material-icons';
        vm.iconLeft.content = 'cloud_download';

        /////////////////////////////////////////////////////
        //----------------- functions ---------------------//
        /////////////////////////////////////////////////////

        function selectFile() {
          //solo deberia haber un elemento en el array
          if (vm.files.length > 0) {
            vm.uploadFile(vm.files[0]);  
          }
        }

        function cleanForm() {
          vm.objFile = {};
          vm.files = [];
          vm.errFiles = [];
          vm.errors = [];
        }

        function onErrorUpload(objFile, error) {
          Notification.processMessage(error.data);
          vm.errors = Notification.processError(error.data);
          console.log('Error durant la importació.');
          $rootScope.$broadcast('$deactivateLoader');
          vm.files = [];
        }

        function onFinishUpload(objFile, response) {
          if (UploadFactory.checkFinalUpload(vm.files)) {
            cleanForm();
            if (!angular.isUndefined(vm.onFinishFunction)) {
              vm.onFinishFunction()(response.data.data.sisId);
            }
          }
          $rootScope.$broadcast('$deactivateLoader');
        }

        function uploadFile(file) {
          vm.errors = [];
          if (angular.isDefined(file) && file !== null) {

            $rootScope.$broadcast('$activateLoader');

            vm.objFile = file;
            vm.objFile.fields = {};
            vm.objFile.fields.newFileId = HelperFactory.uniqueMoment();
            vm.objFile.chunkSize = vm.chunkSize;
            vm.objFile.sizeUrl = 'ok.html';
            vm.objFile.baseUrl = '/api/' + $scope.serverPath;
            vm.objFile.controllerUrl = 'upload';
            vm.objFile.headers = { 'authsecurity' : $cookies.get('authsecurity'), 'x-api-version' : $scope.serverVersion };

            UploadFactory.uploadFile(vm.objFile, onFinishUpload, onErrorUpload).catch(function(error) {
              onErrorUpload(vm.objFile, error);
            });
          }
        }

        

      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint 'no-unused-vars': [2, {'args': 'none'}] */
      }
    };
  }
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name commonControls.utils.tcq-obra-form-upload
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.tcqBc3FormUpload', [
      'ngFileUpload'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.tcqBc3FormUpload.directive:tcqBc3FormUpload
   * @restrict EA
   *
   */
  tcqBc3FormUpload.$inject = ["$cookies"];
  angular
    .module('itecTcqihome.shared.tcqBc3FormUpload')
    .directive('tcqBc3FormUpload', tcqBc3FormUpload);

  function tcqBc3FormUpload($cookies) {
    return {
      restrict: 'EA',
      scope: {
        chunkSize: '@?',
        selectFilesText: '@?',
        saveFilesText: '@',
        maxSize: '@?',
        maxSizeExceededText: '@',
        idTypeDocument: '=',
        listCategories: '=',
        onFinishFunction: '&?',
        serverPath: '@',
        serverVersion: '@'
      },
      templateUrl: 'shared/tcq-bc3-form-upload/tcq-bc3-form-upload-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: ["$scope", "$rootScope", "Notification", "HelperFactory", "UploadFactory", "UploadFileConstants", "$mdDialog", "$document", function ($scope, $rootScope, Notification, HelperFactory, UploadFactory, UploadFileConstants,$mdDialog,$document) {
        var vm = this;
        vm.name = 'tcqBc3FormUpload';

        vm.uploadFile       = uploadFile;
        vm.selectFile       = selectFile;
        vm.cleanForm 		= cleanForm;

        vm.size = 'min';
        vm.files = [];
        vm.errFiles = [];
        vm.errors = [];

        vm.chunkSize = angular.isUndefined($scope.chunkSize) ? UploadFileConstants.CHUNKSIZE : $scope.chunkSize;
        vm.maxSize = angular.isUndefined($scope.maxSize) ? UploadFileConstants.MAX_SIZE_UPLOAD_DEFAULT : $scope.maxSize;

        vm.onFinishFunction = $scope.onFinishFunction;

        vm.iconLeft = {};
        vm.iconLeft.class = 'material-icons';
        vm.iconLeft.content = 'cloud_download';

        /////////////////////////////////////////////////////
        //----------------- functions ---------------------//
        /////////////////////////////////////////////////////

        function selectFile() {
          //solo deberia haber un elemento en el array
        	
        	
          if (vm.files.length > 0) {
        	//   
              $mdDialog.show({
                  controller: ["$scope", "$mdDialog", "parametres", "SisquilloFactory", "Notification", "$translate", function DialogController($scope, $mdDialog, parametres, SisquilloFactory, Notification, $translate) {
                    var vm = this;
                    vm.codi = '';
                    vm.descripcio = '';
                    vm.rotulo =0;
                    vm.errors = [];

                    $scope.accept = function() {

	                    parametres.uploadFile(parametres.file,vm.codi,vm.descripcio,vm.rotulo);  
	                	$mdDialog.hide();
                    };
                    $scope.hide = function() {
                      parametres.cleanForm();
                      $mdDialog.cancel();
                    };
                    $scope.cancel = function() {
                      parametres.cleanForm();
                      $mdDialog.cancel();
                    };
                  }],
                  controllerAs: 'ctrl',
                  templateUrl: 'obres/exportar-a-fie/importar-de-fie-dialog.tpl.html',
                  parent: angular.element($document.body),
                  clickOutsideToClose:true,
                  locals: {'parametres': {cleanForm: vm.cleanForm, uploadFile: vm.uploadFile, file: vm.files[0]}} 
                });
        	  
        	  
        	  
        	  
        	//  
          }
        }

        function cleanForm() {
          vm.objFile = {};
          vm.files = [];
          vm.errFiles = [];
          vm.errors = [];
        }

        function onErrorUpload(objFile, error) {
          Notification.processMessage(error.data);
          vm.errors = Notification.processError(error.data);
          console.log('Error durant la importació.');
          $rootScope.$broadcast('$deactivateLoader');
          vm.files = [];
        }

        function onFinishUpload(objFile, response) {
          if (UploadFactory.checkFinalUpload(vm.files)) {
            cleanForm();
            if (!angular.isUndefined(vm.onFinishFunction)) {
              vm.onFinishFunction()(response.data.data.sisId);
            }
          }
          $rootScope.$broadcast('$deactivateLoader');
        }

        function uploadFile(file,codi,descripcio,rotulo) {
          vm.errors = [];
          if (angular.isDefined(file) && file !== null) {

            $rootScope.$broadcast('$activateLoader');

            vm.objFile = file;
            vm.objFile.fields = {codi:codi,descripcio:descripcio,rotulo:rotulo};
            vm.objFile.fields.newFileId = HelperFactory.uniqueMoment();
            vm.objFile.chunkSize = vm.chunkSize;
            vm.objFile.sizeUrl = 'ok.html';
            vm.objFile.baseUrl = '/api/' + $scope.serverPath;
            vm.objFile.controllerUrl = 'upload/bc3';
            vm.objFile.headers = { 'authsecurity' : $cookies.get('authsecurity'), 'x-api-version' : $scope.serverVersion };

            UploadFactory.uploadFile(vm.objFile, onFinishUpload, onErrorUpload).catch(function(error) {
              onErrorUpload(vm.objFile, error);
            });
          }
        }

        

      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint 'no-unused-vars': [2, {'args': 'none'}] */
      }
    };
  }
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name shared.tables
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.tables', []);
}());

(function () {
  'use strict';

  /**
          Útil per posar tds en un ng-repeat d'un tr



   * @ngdoc directive
   * @name shared.directive:replaceMe
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="shared">
       <file name="index.html">
        <replace-me></replace-me>
       </file>
     </example>
   *
   */
      replaceMe.$inject = ["$compile", "$http", "$templateCache"];
  angular
    .module('itecTcqihome.shared.tables')
    .directive('replaceMe', replaceMe);

  function replaceMe($compile, $http, $templateCache) {
    return {
            restrict: 'A',
            scope: {
               row: '=',
               controlador: '='
            },
            link: function (scope, element, attrs) {
                function getTemplate(template) {
                    $http.get(template, {cache: $templateCache}).success(function (templateContent) {
                        element.replaceWith($compile(templateContent)(scope));
                    });
                }

                scope.$watch(attrs.template, function () {
                    if (attrs.template) {
                        getTemplate(attrs.template);
                    }
                });
            }
        };
      }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.submain
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.submain', [
    ]);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.pdfViewer
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.pdfViewer', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.pdfViewer.directive:mntPdfViewer
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="itecTcqihome.shared.pdfViewer">
       <file name="index.html">
        <mnt-pdf-viewer></mnt-pdf-viewer>
       </file>
     </example>
   *
   */
  angular
    .module('itecTcqihome.shared.pdfViewer')
    .directive('mntPdfViewer', mntPdfViewer);

  function mntPdfViewer() {
    return {
      restrict: 'EA',
      scope: {
        fields                : '=',
        ngModel               : '=',
        baseUrl               : '=',
        paramsUrl             : '=',
        errors                : '=',
        title                 : '@?',
        docButton             : '=?',
        xlsButton             : '=?',
        transcludeButton      : '=?',
        transcludeButtonAction: '&?',
        transcludeButtonText  : '@?',
        preFunction           : '&?',
        titleBuscador         : '@'
      },
      templateUrl: 'shared/pdf-viewer/mnt-pdf-viewer-directive.tpl.html',
      transclude: true,
      controllerAs: 'ctrl',
      controller: ["$scope", "$window", "$sce", function ($scope, $window, $sce) {
        var vm = this;
        vm.name = 'mntPdfViewer';
        vm.pdfUrl = '';

        vm.showPdfLayout        = false;
        vm.showTranscludeLayout = false;
        vm.havingTranscludeOp   = $scope.transcludeButton === true;
        vm.goPdfUrl = goPdfUrl;
        vm.downloadDoc = downloadDoc;
        vm.downloadXls = downloadXls;
        vm.expandedSearch = true;
        
        if (vm.havingTranscludeOp){
          vm.goTransclude = goTransclude;
        }

        $scope.$watch('errors[\'\']', function (newValue, oldValue){
          if (!angular.isUndefined(newValue) && newValue.length > 0) {
            $window.scrollTo(0, 0);
          }
        });

        $scope.trustSrc = function(src) {
         return $sce.trustAsResourceUrl(src);
        }

        function goPdfUrl(){
          vm.showPdfLayout        = true;
          vm.showTranscludeLayout = false;
  
          var params = '?format=pdf' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }
        
        function downloadDoc(){
          var params = '?format=doc' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }

        function downloadXls(){
          var params = '?format=xls' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }

        function goTransclude(){
          vm.showPdfLayout        = false;
          vm.showTranscludeLayout = true;
          $scope.transcludeButtonAction();
        }
        //console.log($scope);

      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        scope.showError = false;

        var iframe = element.find('iframe');
        iframe.bind('load', function (event) {
          if ( iframe[0].innerHTML ) {
            scope.$apply(function(){
              var status = null;
              scope.messageError = '';

              var head = iframe[0].contentDocument.head;
              if (head){
                var metaStatus = head.querySelector('meta[name=status]');
                if (metaStatus !== null) {
                    status = metaStatus.content;
                }
              }
              
              if ( status !== null && status >= 300 ) {
                var metaMessage = head.querySelector('meta[name=message]');
                if (metaMessage !== null) {
                    scope.messageError = metaMessage.content;
                }
                var metaValidations = head.querySelector('meta[name=validations]');
                if (metaValidations !== null) {
                    scope.errors = Notification.processError({validations: JSON.parse(metaValidations.content.replace(new RegExp('\'', 'g'),'"'))});
                }
                scope.showError = true;
              } else {
                scope.showError = false;
                scope.errors = [];
              }
            });
          } else {
            // didn't load
          }
          
        });

        if (angular.isUndefined(scope.submitButtonText)){
          scope.submitButtonText = "DEFAULT_SUBMIT_TEXT";
        }

        scope.$emit('itecColumnFilterDirectiveReady');
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.locale
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.locale', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.shared.locale.factory:LocaleInterceptorFactory
   *
   * @description
   *
   */
  LocaleInterceptorFactory.$inject = ["$cookies", "LocalStorage", "LocaleService"];
  angular
    .module('itecTcqihome.shared.locale')
    .factory('LocaleInterceptorFactory', LocaleInterceptorFactory);

  function LocaleInterceptorFactory($cookies, LocalStorage, LocaleService) {
    var requestInterceptor = {
      response: function(response) {
        var localeCookie = $cookies.get('org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE');
        if (!angular.isUndefined(localeCookie)) {
          var locale = LocalStorage.get('locale');
          if (locale !== localeCookie) {
            LocalStorage.save('locale', localeCookie);
            LocaleService.setLocale(localeCookie);
          }  
        }
        
        return response;
      }
    };
    return requestInterceptor;
  }

}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.estat
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.headerSubmenu', []);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.shared.headerSubmenu.controller:HeaderSubmenuCtrl
   *
   * @description
   *
   */
  HeaderSubmenuCtrl.$inject = ["optionsHeaderSubmenuData", "AppHeaderSubmenuFactory", "PermisosFactory", "$rootScope", "$scope", "$state", "$stateParams"];
  angular
    .module('itecTcqihome.shared.headerSubmenu')
    .controller('HeaderSubmenuCtrl', HeaderSubmenuCtrl);

  function HeaderSubmenuCtrl(
                optionsHeaderSubmenuData,
                AppHeaderSubmenuFactory, 
                PermisosFactory,
                $rootScope, $scope, $state, $stateParams) {
    var vm = this;   
    
    if (!angular.isUndefined(optionsHeaderSubmenuData.addPermit) && optionsHeaderSubmenuData.addPermit !== null && (angular.isUndefined($stateParams.submenuInfo.left.addButton) || $stateParams.submenuInfo.left.addButton)) {
      $stateParams.submenuInfo.left.addButton = PermisosFactory.isVisible(optionsHeaderSubmenuData.addPermit, 'add');
    }
    
    $scope.$on(optionsHeaderSubmenuData.name + '.showOptionSubmenu',function(event, data){
      if (angular.isUndefined(data.onlyFor)) {
        if (data.show){
           angular.forEach(optionsHeaderSubmenuData.options, function(key){
              key.show = PermisosFactory.isVisible(key.permit, 'read');
            });
        }else{
          angular.forEach(optionsHeaderSubmenuData.options, function(key){
            key.show = false;
          });
        }
      }
      else {
        //primer tot a false
        angular.forEach(optionsHeaderSubmenuData.options, function(key){
            key.show = false;
        });
        //a true tots els menus que no tenen camp onlyFor o el tenen i coincideix amb el data.onlyFor
        angular.forEach(optionsHeaderSubmenuData.options, function(key){
          if(!angular.isUndefined(key.onlyFor)) {
            if (data.onlyFor === key.onlyFor) {
              key.show = PermisosFactory.isVisible(key.permit, 'read');
            }
          }
          else {
            key.show = PermisosFactory.isVisible(key.permit, 'read');
          }
        });
      }


    });
    
    AppHeaderSubmenuFactory.resize($state, $stateParams, optionsHeaderSubmenuData.name);

    AppHeaderSubmenuFactory.createAttributes(vm, optionsHeaderSubmenuData.options, $stateParams, false);


  }
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.breadcrumb
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.breadcrumb', [
      'itecGuicomponents.layout.wrapper.breadcrumb'
    ]);
    // .config(function(ItecBreadcrumbProvider) {
    //   ItecBreadcrumbProvider.compileWith(function(currentState, $interpolate) {
    //     var state = currentState.self;
    //     if(!state.breadcrumb) { return null; }
    //     if(typeof state.breadcrumb === 'string') {
    //       return {
    //         text: state.breadcrumb,
    //         stateName: state.name
    //       };
    //     } else {
    //       var breadcrumb = angular.copy(state.breadcrumb);

    //       if (breadcrumb.dynamicText) {
    //         breadcrumb.text=$interpolate(breadcrumb.dynamicText)(currentState.locals.globals);
    //       }

    //       if (breadcrumb.list){
    //         var list = JSON.parse($interpolate(breadcrumb.list)(currentState.locals.globals));
    //         var breadcrumbs = [];
    //         angular.forEach(list, function(obj){
    //           var aux = {};
    //           aux.stateName = breadcrumb.stateName;
    //           if (breadcrumb.stateParams){ //tractament per N paràmetres d'estat
    //             var result = '';
    //             angular.forEach(breadcrumb.stateParams, function(stateParam, index){
    //               if (index > 0){
    //                 result += ', ';
    //               }
    //               result = result + '\"' + stateParam.paramName + '\" : \"' + obj[stateParam.paramProperty] + '\"';
    //             });
    //             aux.stateName = aux.stateName + '({' + result + '})';
    //           } else if (breadcrumb.stateParam) {
    //             aux.stateName = aux.stateName + '({' + breadcrumb.stateParam.paramName + ' : ' + obj[breadcrumb.stateParam.paramProperty] + '})';
    //           }
    //           aux.class = breadcrumb.class;
    //           aux.text = breadcrumb.text;
    //           if (breadcrumb.textParams){
    //             var result = '';
    //             angular.forEach(breadcrumb.textParams, function(textParam, index){
    //               if (index > 0){
    //                 result += ', ';
    //               }
    //               result = result + '\"' + textParam.paramName + '\" : \"' + obj[textParam.paramProperty] + '\"';
    //             });
    //             aux.textParams = JSON.parse('{' + result + '}');
    //           } else if (breadcrumb.textParam) {
    //             aux.textParams = JSON.parse('{\"' + breadcrumb.textParam.paramName + '\" : \"' + obj[breadcrumb.textParam.paramProperty] + '\"}');  
    //           } else {
    //             aux.textParams = {};
    //           }
              
    //           breadcrumbs.push(aux);
    //         });
    //         return breadcrumbs;
    //       } else {
            
    //         if (breadcrumb.textParams){ //tractament per N paràmetres de text
    //           var result = '';
    //           angular.forEach(breadcrumb.textParams, function(textParam, index){
    //             if (index > 0){
    //               result += ', ';
    //             }
    //             result = result + '\"' + textParam.paramName + '\" : \"' + $interpolate(textParam.paramValue)(currentState.locals.globals) + '\"';
    //           });
    //           breadcrumb.textParams = JSON.parse('{' + result + '}');
    //         } else if (breadcrumb.textParam) { //tractament per un paràmetre de text
    //           breadcrumb.textParams = JSON.parse('{\"' + breadcrumb.textParam.paramName + '\" : \"' + $interpolate(breadcrumb.textParam.paramValue)(currentState.locals.globals) + '\"}');
    //         } else {
    //           breadcrumb.textParams = {};
    //         }

    //         if (breadcrumb.stateParams){ //tractament per N paràmetres d'estat
    //           var result = '';
    //           angular.forEach(breadcrumb.stateParams, function(stateParam, index){
    //             if (index > 0){
    //               result += ', ';
    //             }
    //             result = result + '\"' + stateParam.paramName + '\" : \"' + $interpolate(stateParam.paramValue)(currentState.locals.globals) + '\"';
    //           });
    //           breadcrumb.stateName = breadcrumb.stateName + '({' + result + '})';
    //         } else if (breadcrumb.stateParam) { //tractament per un paràmetre d'estat
    //           breadcrumb.stateName = breadcrumb.stateName + '({\"' + breadcrumb.stateParam.paramName + '\" : \"' + $interpolate(breadcrumb.stateParam.paramValue)(currentState.locals.globals) + '\"})';
    //         }
    //       }
    //       return breadcrumb;
    //     }
    //   });
    // });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.obres.dades
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres.dades', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('itecTcqihome.obres.dades')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('obres.dades', {
        url: '/{idObra:[0-9]{0,32}}/dades',
        views: {
          'wrapper@': {
              templateUrl : 'obres/dades/dades.tpl.html',
              controller  : 'ObresDadesCtrl',
              controllerAs: 'ctrl'
          }
        },
        resolve: {
          obraData: ["SisquilloFactory", "$stateParams", function(SisquilloFactory, $stateParams) {
            if (!angular.isUndefined($stateParams.isNew) &&
                $stateParams.isNew !== null && $stateParams.isNew) {

                return {};
            }
            else if (!angular.isUndefined($stateParams.idObra) &&
                     $stateParams.idObra !== null && $stateParams.idObra) {

              return SisquilloFactory.get($stateParams.idObra).catch(function(){});
            }
            else {
              return {};
            }
          }],
          dadesGeneralsData: ["SisquilloFactory", function(SisquilloFactory) {
            return SisquilloFactory.getDadesGenerals().catch(function(){});
          }],
          tipologies: ["SisquilloFactory", function(SisquilloFactory) {
            return SisquilloFactory.getTipologies().catch(function(){});
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name plantillaConductiuEspai.dades.controller:ObresDadesCtrl
   *
   * @description
   *
   */
  ObresDadesCtrl.$inject = ["SisquilloFactory", "TipologiaPreguntaConstants", "HelperFactory", "$stateParams", "obraData", "$state", "$mdDialog", "ItecTreeTracking", "$rootScope", "$window", "$scope", "Notification", "$translate", "dadesGeneralsData", "tipologies", "ImatgeFactory", "ApiLayoutConstant", "$cookies", "TcqihomeConstant"];
  angular
    .module('itecTcqihome.obres.dades')
    .controller('ObresDadesCtrl', ObresDadesCtrl);

  // $mdDialog
  function ObresDadesCtrl(SisquilloFactory, TipologiaPreguntaConstants, HelperFactory, $stateParams, obraData, $state, $mdDialog, ItecTreeTracking, $rootScope, $window, $scope, Notification, $translate, dadesGeneralsData, tipologies, ImatgeFactory, ApiLayoutConstant, $cookies, TcqihomeConstant) {
    TipologiaDialogController.$inject = ["obra", "tipologies"];
    var vm = this;
    vm.ctrlName = 'ObresDadesCtrl';


    vm.back = back;
    vm.desarObra = desarObra;
    vm.refresh = refresh;
    vm.tipologies = tipologies.data;

    vm.ApiLayoutConstant = ApiLayoutConstant;
    vm.TcqihomeConstant = TcqihomeConstant;
    vm.TipologiaPreguntaConstants = TipologiaPreguntaConstants

    vm.iconLeft = {};
    vm.iconLeft.class = 'material-icons';
    vm.iconLeft.content = 'file_upload';

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';
    vm.isNew = false;

    init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME} , $cookies.get('authsecurity')).then( function(response){
      vm.headers = {
          'x-api-version': ApiLayoutConstant.SERVER_VERSION_TCQIHOME,
          'authtcqihome': response.app.auth
      };
    });

    vm.errors = [];

    if (angular.isUndefined(obraData) || angular.isUndefined(obraData.data)) {
      HelperFactory.setParams($stateParams, {'idObra': undefined});
      vm.obra = {};
      vm.obra.monedes = [];
      vm.obra.monedes.push(dadesGeneralsData.data.monedaDefault);
      vm.isNew = true;
    } else {
      vm.obra = obraData.data.sisquillo;
      vm.obra.monedes = [];
      vm.obra.monedes.push(vm.obra.moneda);
      vm.isNew = false;
    }
    vm.maxUploadSize = dadesGeneralsData.data.maxUploadSizeImages;

    vm.formulari = {
      nom: 'dadesGenerals',
      func: function() {
        return vm.desarObra();
      },
      camps: [
          {
            type: 'text',
            name: 'codi',
            label: 'DEFAULT_CODI',
            columns: 6,
            required: true,
            disabled: vm.obra.teSeguiment,
            maxLength: 50
          },
          {
            type: 'text',
            name: 'descripcio',
            label: 'DEFAULT_TITOL_OBRA',
            columns: 6,
            required: true,
            disabled: vm.obra.teSeguiment,
            maxLength: 2000
          },
          {
            type: 'chips',
            name: 'monedes',
            label: 'DEFAULT_MONEDA',
            fieldProperty: 'descripcion',
            textPlaceholder: $translate.instant('DEFAULT_MONEDA'),
            list: dadesGeneralsData.data.monedaList,
            required: true,
            readonly: vm.obra.teSeguiment,
            columns: 6,
            mdMaxChips: 2
          },
          {
            type: 'select',
            simple: true,
            name: 'tipologia',
            label: 'DEFAULT_TIPOLOGIA',
            list: dadesGeneralsData.data.tipologiaList,
            required: true,
            disabled: vm.obra.teSeguiment,
            columns: 6
          },
          {
            type: 'select',
            simple: true,
            name: 'contractacio',
            label: 'DEFAULT_CONTRACTACIO',
            list: dadesGeneralsData.data.contractacioList,
            required: false,
            disabled: vm.obra.teSeguiment,
            columns: 6
          },
          {
            type: 'select',
            simple: true,
            name: 'actuacio',
            label: 'DEFAULT_TIPUS_ACTUACIO',
            list: dadesGeneralsData.data.actuacioList,
            required: false,
            disabled: vm.obra.teSeguiment,
            columns: 6
          },
          {
            type: 'textarea',
            name: 'notes',
            label: 'DEFAULT_NOTES',
            required: false,
            disabled: vm.obra.teSeguiment,
            columns: 12,
            maxLength: 2000
          },
          {
            type: 'site',
            name: 'site_coordinates',
            latitud: 'site_latitud',
            longitud: 'site_longitud',
            label: 'DEFAULT_LOCALITZACIO',
            required: false,
            disabled: vm.obra.teSeguiment,
            columns: 12
          }
      ]
    };

    function back() {
      $state.go('^', $stateParams, {reload: true});
    }

    function refresh() {
      $state.go('.', $stateParams, {reload: true});
    }

    function desarObra() {
      if (angular.isDefined(vm.obra.monedes[0])) {
        vm.obra.moneda = vm.obra.monedes[0];
      }
      if (vm.isNew) {
        return SisquilloFactory.add(vm.obra).then(function(response) {
          Notification.success('OBRES_CREATE_SUCCESS');
          HelperFactory.setParams($stateParams, {'idObra': response.data});
          $state.go('.', $stateParams, {'reload':true});
        }).catch(function(error) {
          vm.errors = Notification.processError(error);
        });

      } else {
        return SisquilloFactory.update(vm.obra.id, vm.obra).then(function() {
          Notification.success('OBRES_UPDATE_SUCCESS');
        }).catch(function(error) {
          vm.errors = Notification.processError(error);
        });
      }

    }

    vm.selectTipologia = function() {
      $mdDialog.show({
        locals: {obra: vm.obra, tipologies: vm.tipologies},
        controller: TipologiaDialogController,
        controllerAs: 'ctrl',
        templateUrl: 'obres/dades/tipologies.tpl.html',
        parent: angular.element(document.body),
        title:'DEFAULT_PREVENCIO_MINIMITZACIO',
        clickOutsideToClose:true,
        escapeToClose:true
      });
    }

    $scope.$on ('tipologia-new-preguntes', function (evt, list) {

      // Copiem les respostes que ja existeixen
      angular.forEach (vm.obra.listPreguntes, function (preguntaExisting){
        angular.forEach (list, function (preguntaNova){
          if (preguntaExisting.idTipologiaPregunta === preguntaNova.idTipologiaPregunta) {
            preguntaNova.valorBoolea = preguntaExisting.valorBoolea;
            preguntaNova.valorNumeric = preguntaExisting.valorNumeric;
            preguntaNova.valorCadena = preguntaExisting.valorCadena;
          }
        });

      });

      vm.obra.listPreguntes = list;
    });

    vm.getDescPregunta = function(pregunta) {
      var result = pregunta.nom;
      if ((pregunta.ua !== null) && (pregunta.ua !== '')) {
        result += ' (' + pregunta.ua + ')';
      }
      return result;
    };


    function TipologiaDialogController (obra, tipologies) {
      var ctrl = this;
      ctrl.obra = obra;
      ctrl.tipologies = [];
      ctrl.expanded = [];


      // Monta l'arbre de tipologies a partir de la llista
      ctrl.addChildren = function (parent) {

        if (parent !== undefined) {
          parent.children = [];
        }
        angular.forEach (tipologies, function (item) {
          if ((parent === undefined) && (item.idPare === null)) {
            ctrl.tipologies.push (item);
            ctrl.addChildren (item);
            // El node rel sempre surt expanded
            ctrl.expanded.push(item);
          }

          if ((parent !== undefined) && (item.idPare === parent.id)) {
            parent.children.push(item);
            ctrl.addChildren (item);
          }
        });
      };

      // fica l'item amb el id a expanded i expandeix el seu pare
      ctrl.expandItemAndParent = function (id) {
        if (id === null) return;
        angular.forEach (tipologies, function (item) {
          if (item.id === id) {
            ctrl.expanded.push(item);
            ctrl.expandItemAndParent (item.idPare);
          }
        });
      };

      // Obte l'element checkejat o null si no n'hi ha cap
      ctrl.getItemChecked = function () {
        var result = null;
        angular.forEach (tipologies, function (item) {
          if (item.checked) {
            result = item;
          }
        });
        return result;
      };

      // Busquem el item seleccionat i critem a expandir tot el seu cami
      ctrl.expandCheckedPath = function () {
        var item = ctrl.getItemChecked();
        if (item !== null) {
          ctrl.expanded = [];
          ctrl.expandItemAndParent(item.idPare);
        }
      };

      // Busqumne el node i el fiquem a checked
      ctrl.setTipologiaToChecked = function(node) {
        if ((node !== null) && (node !== undefined)) {
          angular.forEach (tipologies, function (item) {
            if (item.id == node.id) {
              item.checked = true;
            }
            else {
              item.checked = false;
            }
          });
        }
      };

      ctrl.setTipologiaToChecked (ctrl.obra.tipologiaEDC);
      ctrl.addChildren ();
      ctrl.expandCheckedPath ();

      // Quan checkejen fiquem la resta a unchecked
      ctrl.changeCheck = function(node) {
        if (node.checked) {
          ctrl.setTipologiaToChecked(node);
        }
      };

      ctrl.confirm = function() {
        var item = ctrl.getItemChecked();
        ctrl.obra.tipologiaEDC = item;
        SisquilloFactory.getSisquilloTipologies(ctrl.obra.id, item.id).then(function (response) {
          $rootScope.$broadcast('tipologia-new-preguntes', response.data);
          $mdDialog.hide();

        }).catch(function (error) {
          ctrl.errors = Notification.processError(error);
        });

      };

      ctrl.cancel = function () {
        $mdDialog.hide();
      };

    };



  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.sisquillo
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.sisquillo', [
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.sisquillo.factory:SisquilloFactory
   *
   * @description
   *
   */
  SisquilloFactory.$inject = ["DataFactory", "ApiLayoutConstant"];
  angular
    .module('itecTcqihome.api.sisquillo')
    .factory('SisquilloFactory', SisquilloFactory);

  function SisquilloFactory(DataFactory, ApiLayoutConstant) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres';

    var base = {

      getAll: function(){
        var url = api;
        return DataFactory.get(url);
      },

      getDadesGenerals: function(){
        var url = api + '/dades_generals';
        return DataFactory.get(url);
      },

      getTipologies: function(){
        var url = api + '/tipologies/all';
        return DataFactory.get(url);
      },

      get: function(id){
        var url = api + '/' + id;
        return DataFactory.get(url);
      },

      getSisquilloTipologies: function (idSisquillo, idTipologia) {
        var url;
        if (idSisquillo !== undefined) {

          url = api + '/' + idSisquillo + '/' + idTipologia + '/preguntes';
        }
        else {
          url = api + '/' + idTipologia + '/preguntes';
        }
        return DataFactory.get(url);
      },

      update: function(id, obj){
        var url = api + '/' + id;
        return DataFactory.update(url,obj);
      },

      add: function(obj){
        var url = api;
        return DataFactory.add(url,obj);
      },

      delete: function(id){
        var url = api;
        return DataFactory.delete(url, id);
      },

      download: function(id) {
        var url = api + '/' + id +'/download';
        return DataFactory.get(url);
      }

    };
    return base;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.logout
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.logout', [
    ]);
}());

(function () {
'use strict';

/**
 * @ngdoc service
 * @name itecTcqihome.api.logout.factory:LogoutFactory
 *
 * @description
 *
   */
    LogoutFactory.$inject = ["ApiLayoutConstant", "DataFactory"];
  angular
    .module('itecTcqihome.api.logout')
    .factory('LogoutFactory', LogoutFactory);

    function LogoutFactory(ApiLayoutConstant, DataFactory) {
      var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'j_spring_security_logout';

      var LogoutBase = {

        get: function() {
          return DataFactory.get(api);
        }

      };
      return LogoutBase;
    }
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name api.locale
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.locale', [
      'itecApi'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.locale.factory:LocaleFactory
   *
   * @description
   *
   */
  LocaleFactory.$inject = ["DataFactory", "ApiLayoutConstant"];
  angular
    .module('itecTcqihome.api.locale')
    .factory('LocaleFactory', LocaleFactory);

  function LocaleFactory(DataFactory, ApiLayoutConstant) {
    var api = ApiLayoutConstant.SERVER_PATH_MANTENIMENT + 'locales';

    var base = {
      getAll: function(){
        var url = api;
        return DataFactory.get(url);
      },
      getCurrent: function(){
        var url = api + '/session';
        return DataFactory.get(url);
      },
      updateCurrent: function(obj){
        var url = api + '/session';
        return DataFactory.update(url,obj);
      }
    };
    return base;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.imatge
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.imatge', [
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.imatge.factory:ImatgeFactory
   *
   * @description
   *
   */
  ImatgeFactory.$inject = ["DataFactory", "ApiLayoutConstant", "$http", "$cookies"];
  angular
    .module('itecTcqihome.api.imatge')
    .factory('ImatgeFactory', ImatgeFactory);

  function ImatgeFactory(DataFactory, ApiLayoutConstant, $http, $cookies) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'imatges';

    var base = {

      download: function(id, size, idSisquillo){
        var url = '/api/' + ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres/' + idSisquillo + '/imatges/' + id + '/download?size=' + size;

        var promise = init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME},
            $cookies.get('authsecurity')).then( function(response){
          var headers = {};
          headers['x-api-version'] = ApiLayoutConstant.SERVER_VERSION_TCQIHOME;
          headers['authsecurity'] = $cookies.get('authsecurity');
          headers['authtcqihome'] = response.app.auth;
          headers['responseType'] = 'arraybuffer';
          return $http.get(url, {headers: headers});
        });
        return promise;
      }

    };
    return base;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name shared
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared', [
      'itecTcqihome.shared.tables',
      'itecTcqihome.shared.pdfViewer',
      'itecTcqihome.shared.breadcrumb',
      'itecTcqihome.shared.headerSubmenu',
      'itecTcqihome.shared.submain',
      'itecTcqihome.shared.locale',
      'itecTcqihome.shared.tcqObraFormUpload',
      'itecTcqihome.shared.tcqBc3FormUpload'
    ]);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.obres
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres', [
      'ui.router',
      'itecTcqihome.obres.dades'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('itecTcqihome.obres')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('obres', {
        url: '/obres',
        views: {          
          'wrapper': {
            templateUrl: 'obres/obres.tpl.html',
            controller: 'ObresCtrl',
            controllerAs: 'ctrl',
            resolve: {
              allSisquillos: ["SisquilloFactory", "ApiLayoutConstant", "PermisosFactory", "$rootScope", function(SisquilloFactory, ApiLayoutConstant, PermisosFactory, $rootScope) {
                if (angular.isUndefined($rootScope.acces) || $rootScope.acces === null ) {
                  PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(
                    function(response) {
                      $scope.$apply(function() {
                        $rootScope.acces = response.data.acces;
                      });
                    }).catch(function(){});
                }
                return SisquilloFactory.getAll().catch(function(){});
              }]
            }
          }
        }
      });
  }
}());
(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.obres.filter:obraByCodiAndDescripcio
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres')
    .filter('obraByCodiAndDescripcio', obraByCodiAndDescripcioFilter);

  function obraByCodiAndDescripcioFilter() {
    return function (items, search) {
        var result = [];
        angular.forEach(items, function (value, key) {
          if (value.codi.indexOf(search) !== -1 || value.descripcio.indexOf(search) !== -1) {
              result.push(value);
          }
        });
        return result;

    }
  }
}());
(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.obres.controller:ObresCtrl
   *
   * @description
   *
   */
  ObresCtrl.$inject = ["SisquilloFactory", "HelperFactory", "ConvertFactory", "FileSaver", "Notification", "$cookies", "$http", "$rootScope", "$scope", "$state", "$stateParams", "$window", "ApiLayoutConstant", "ImatgeFactory", "allSisquillos", "$mdDialog", "$document", "UploadFileConstants"];
  angular
    .module('itecTcqihome.obres')
    .controller('ObresCtrl', ObresCtrl);

  function ObresCtrl(SisquilloFactory, HelperFactory, ConvertFactory,FileSaver, Notification, $cookies, $http,
      $rootScope, $scope, $state, $stateParams, $window, ApiLayoutConstant, ImatgeFactory, allSisquillos,
      $mdDialog, $document, UploadFileConstants) {

    var vm = this;
    vm.ctrlName = 'ObresCtrl';

    vm.obres = allSisquillos.data;
    vm.fields = [];

    vm.viewDades = viewDades;
    vm.esborrar = esborrar;
    vm.exportarObra = exportarObra;
    vm.exportarObraFIE = exportarObraFIE;
    vm.afegir = afegir;
    vm.loadObres = loadObres;
    vm.openObra = openObra;

    vm.size = UploadFileConstants.SIZE_MINIMAL;
    vm.listMode = true;

    vm.addButton = {};
    vm.addButton.class = 'material-icons tcqi--button-icon-left';
    vm.addButton.content = 'add';

    vm.searchText = '';

    vm.sortType = '';
    vm.sortReverse  = false;

    vm.ApiLayoutConstant = ApiLayoutConstant;

    vm.tcqiApps = {
      'security': {
        'image': $rootScope.prefixItecGuicomponents +
                'images/layout/header/menu/directives/images/logos/security.png',
        'app': ApiLayoutConstant.APP_NOM_ADMINISTRACIO,
        'title': 'DEFAULT_TCQI_SECURITY',
        'descr': ''
      },
      'manteniment': {
        'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/manteniment.png',
        'app': ApiLayoutConstant.APP_NOM_MANTENIMENT,
        'title': 'DEFAULT_TCQI_MANTENIMENT',
        'descr': ''
       },
       'areacollaborativa': {
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/areaColaborativa.png',
         'app': ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA,
         'title': 'DEFAULT_TCQI_AREA_COLABORATIVA',
         'descr': ''
       },
       'comparacioofertes': {
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/comparacioOfertes.png',
         'app': ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES,
         'title': 'DEFAULT_TCQI_COMPARACIO_OFERTES',
         'descr': ''
        },
        'estimaciocostos': {
          'image': $rootScope.prefixItecGuicomponents +
                     'images/layout/header/menu/directives/images/logos/estimaciocostos.png',
          'app': ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,
          'title': 'DEFAULT_TCQI_ESTIMACIO_COSTOS',
          'descr': ''
         }
      };

    function viewDades(id) {
      if (angular.isDefined(id) && id !== null) {
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }

    function esborrar(id) {
      $rootScope.$broadcast('$activateLoader');
      SisquilloFactory.delete(id).then(function(){
        $scope.$apply(function() {
          HelperFactory.removeFromArray(vm.obres, id);
        });
        $rootScope.$broadcast('$deactivateLoader');
      }).catch(function(error){
        Notification.processError(error);
        $rootScope.$broadcast('$deactivateLoader');
      });
    }

    function exportarObra(id) {
      //aqui se exportará la obra
      var url = '/api/' + ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres/' + id + '/download';
        init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME},
            $cookies.get('authsecurity')).then( function(response){
          var headers = {};
          headers['x-api-version'] =  ApiLayoutConstant.SERVER_VERSION_TCQIHOME;
          headers['authsecurity'] = $cookies.get('authsecurity');
          headers['authtcqihome'] = response.app.auth;
          headers['responseType'] = 'arraybuffer';
          $http.get(url, {headers: headers}).then(function(response){
            var blob = ConvertFactory.b64toBlob(response.data, response.headers('Content-Type'));
            FileSaver.saveAs(blob, response.headers('filename'));
          });
        });
    }

    function exportarObraFIE(id) {
      $mdDialog.show({
        controller: ["$scope", "$mdDialog", "SisquilloFactory", "Notification", "$translate", function DialogController($scope, $mdDialog, SisquilloFactory, Notification, $translate) {
          var vm = this;
          vm.export_plecs = true;
          vm.export_grafics = true;
          vm.export_amid = true;
          vm.errors = [];

          $scope.accept = function() {
              //aqui se exportará la obra a FIE
              var url = '/api/' + ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres/' + id + '/downloadfie/'  +
                '?volPlecs=' + vm.export_plecs + '&volGrafics=' + vm.export_grafics +
                '&volAmids=' + vm.export_amid;

              init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME },
                  $cookies.get('authsecurity')).then( function(response) {

                  var headers = {};
                  headers['x-api-version'] =  ApiLayoutConstant.SERVER_VERSION_TCQIHOME;
                  headers['authsecurity'] = $cookies.get('authsecurity');
                  headers['authtcqihome'] = response.app.auth;
                  headers['responseType'] = 'arraybuffer';
                  $http.get(url, {headers: headers}).then(function(response){
                   $rootScope.$broadcast('$activateLoader');

                	var blob = ConvertFactory.b64toBlob(response.data, response.headers('Content-Type'));
                    FileSaver.saveAs(blob, response.headers('filename'));
                    $rootScope.$broadcast('$deactivateLoader');
                    Notification.success("DEFAULT_ACCIO_FINALITZADA_EXIT");
                  });
                });
                $mdDialog.hide();
          };
          $scope.hide = function() {
            $mdDialog.cancel();
          };
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
        }],

        controllerAs: 'ctrl',
        templateUrl: 'obres/exportar-a-fie/exportar-a-fie-dialog.tpl.html',
        parent: angular.element($document.body),
        clickOutsideToClose:true
      });
    }

    function openObra(id, app) {
      if (app === ApiLayoutConstant.APP_NOM_PRESSUPOST) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_PRESSUPOST +
          '/pressupost/#/' + id + '/obra/estructura');
      } else if (app === ApiLayoutConstant.APP_NOM_QUALITAT) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_QUALITAT +
          '/qualitat/#/' + 'obra/' + id );
      } else if (app === ApiLayoutConstant.APP_NOM_MEDIAMBIENT) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_MEDIAMBIENT +
          '/mediambient/#/' + 'obres/' + id + '/obra/indicadors/informacio');
      } else if (app === ApiLayoutConstant.APP_NOM_SEGURETATISALUT) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_SEGURETATISALUT +
          '/seguretatisalut/#/' + 'obra/' + id);
      } else if (app === ApiLayoutConstant.APP_NOM_PLANIFICACIO) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_PLANIFICACIO +
           '/planificacio/#/' + 'obres/' + id + '/tasques/gantt/diagrama');
      } else if (app === ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_SEGUIMENTECONOMIC +
          '/seguimenteconomic/#/' + id + '/obra/estructura');
      } else if (app === ApiLayoutConstant.APP_NOM_MANTENIMENT) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_MANTENIMENT +
          '/manteniment');
      } else if (app === ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + 'areacolaborativa');
      } else if (app === ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + 'comparadorofertes');
      } else if (app === ApiLayoutConstant.APP_NOM_ADMINISTRACIO) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_ADMINISTRACIO +
          '/security');
      } else if (app === ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_ESTIMACIOCOSTOS +
          '/estimaciocostos');
      } else if (app === ApiLayoutConstant.APP_NOM_CONTROLCOSTOS) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_CONTROLCOSTOS +
          '/controlcostos/#/obra/' + id + '/arxiu');
      }
    }

    function afegir(){
      $window.scrollTo(0, 0);
      HelperFactory.setParams($stateParams, {'isNew': true, 'idObra': null});
      $state.go('obres.dades', $stateParams);
    }

    function loadObres() {
      angular.forEach(vm.obres, function(obra, index) {
        if (obra.imatge) {
          ImatgeFactory.download(obra.imatge.id, vm.size, obra.id).then(function(response2) {
            vm.obres[index].imatge.data = response2.data;
          });
        }
      });
    }

    vm.loadObres();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:theme
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('theme', 'tcqihome');
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:headerMenuLinks
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('headerMenuLinks', {
        '0': {
          'name': 'DEFAULT_TCQI',
          'state': 'obres',
          'onclick': ''
        }
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:headerMenuIconsPosition
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('headerMenuIconsPosition', 'up'); // Opciones: 'up', 'down', 'left' o 'right'.
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name api
   * @description
   *
   */
  angular
    .module('itecTcqihome.api', [
      'ui.router',
      'itecTcqihome.api.locale',
      'itecTcqihome.api.imatge',
      'itecTcqihome.api.logout',
      'itecTcqihome.api.sisquillo'
    ]);
}());
(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('itecTcqihome.api')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('api', {
        url: '/api',
        templateUrl: 'api/views/api.tpl.html',
        controller: 'ApiCtrl',
        controllerAs: 'api'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$urlRouterProvider"];
  angular
    .module('itecTcqihome')
    .config(config);

  function config($urlRouterProvider) {
    $urlRouterProvider.when('', '/obres');
    $urlRouterProvider.otherwise('/sample');
  }
}());

(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name itecTcqihome.controller:AppCtrl
     *
     * @description
     *
     */
    AppCtrl.$inject = ["theme", "headerMenuLinks", "headerMenuIconsPosition", "$timeout", "ApiLayoutConstant", "DataFactory", "$location", "$scope", "AppWrapperFactory", "$rootScope", "LogoutFactory", "PermisosFactory", "LocaleService"];
    angular
      .module('itecTcqihome')
      .controller('AppCtrl', AppCtrl);

    function AppCtrl(theme, headerMenuLinks, headerMenuIconsPosition, $timeout, ApiLayoutConstant,
        DataFactory, $location, $scope, AppWrapperFactory, $rootScope, LogoutFactory, PermisosFactory,
        LocaleService) {
      var vm = this;

      $rootScope.modul = theme;

      ApiLayoutConstant.SERVER_PATH_MANTENIMENT_COMPLETE_API =
        'https://'+$location.$$host+'/api/manteniment/';
      ApiLayoutConstant.SERVER_ROOT_PATH = 'https://'+$location.$$host+'/';
      ApiLayoutConstant.SERVER_PATH_MANTENIMENT_REPORTS_API =
        'https://'+$location.$$host+'/api/reportsmanteniment/b2b/';


      vm.app = {};
      vm.app.theme = theme;
      vm.app.headerMenuLinks = headerMenuLinks;
      vm.app.headerMenuIconsPosition = headerMenuIconsPosition;

      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'permisos').then(function(response){
        $scope.$apply(function(){
          $rootScope.permisos = response.data.permisos;
          $rootScope.usuari = response.data.usuari;
          LocaleService.setLocaleByIdioma($rootScope.usuari.idioma);
        });
      });

      vm.accesosAppsTCQi = undefined;
      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(function(response) {
        vm.accesosAppsTCQi = response.data.acces;
        $rootScope.acces = response.data.acces;
      }).catch(function(){});

      AppWrapperFactory.init($scope);


      vm.loaded = false;
      $scope.$on('$viewContentLoaded', function(ev, data){
        $timeout(function() {
          vm.loaded = true;
          $rootScope.$broadcast('tcqiAppLoaded');
        },0);
      });

      vm.logout=logout;

      function logout() {
        var promise = LogoutFactory.get().then(function(response){

        }).catch(function(error){

        });
        return promise;
      }
    }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:app
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .constant('TcqihomeConstant', {
      'AUTH_TCQIHOME':'authtcqihome',
      'UPLOAD_OBRA_MAX_SIZE': '60MB',
      'Decimals': 2
    })
    .constant('TipologiaPreguntaConstants', {

      'Numeric': 0,
      'Cadena': 1,
      'Boolea': 2
    });



}());
