<md-dialog  aria-label="{{'DEFAULT_EXPORTAR_FIE' | translate}}">
  <form>
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2>{{'DEFAULT_EXPORTAR_FIE' | translate}}</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="hide()">
          <md-icon aria-label="{{'DEFAULT_TANCAR' | translate}}">close</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-dialog-content>
      <div class="md-dialog-content">
        <div class="mdl-grid">

        <div class="mdl-cell mdl-cell--12-col">
          <itec-error-block errors="ctrl.errors['']"></itec-error-block>
        </div>
    
        <div class="mdl-cell mdl-cell--12-col">
		<itec-checkbox
			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
     		ng-model="ctrl.export_plecs">
		</itec-checkbox>
		{{'DEFAULT_EXPORT_FIE_PLECS' | translate }}
        </div>

        <div class="mdl-cell mdl-cell--12-col">
		<itec-checkbox
			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
     		ng-model="ctrl.export_grafics">
		</itec-checkbox>
		{{'DEFAULT_EXPORT_FIE_GRAFICS' | translate }}
        </div>

        <div class="mdl-cell mdl-cell--12-col">
		<itec-checkbox
			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
     		ng-model="ctrl.export_amid">
		</itec-checkbox>
		{{'DEFAULT_EXPORT_FIE_AMID' | translate }}
        </div>
        
      </div>
    </md-dialog-content>
    <md-dialog-actions layout="row">
      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">
       {{'DEFAULT_CANCELAR' | translate}}
      </md-button>
      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">
        {{'DEFAULT_ACCEPTAR' | translate}}
      </md-button>
    </md-dialog-actions>
  </form>
</md-dialog>