(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.tcqBc3FormUpload.directive:tcqBc3FormUpload
   * @restrict EA
   *
   */
  angular
    .module('itecTcqihome.shared.tcqBc3FormUpload')
    .directive('tcqBc3FormUpload', tcqBc3FormUpload);

  function tcqBc3FormUpload($cookies) {
    return {
      restrict: 'EA',
      scope: {
        chunkSize: '@?',
        selectFilesText: '@?',
        saveFilesText: '@',
        maxSize: '@?',
        maxSizeExceededText: '@',
        idTypeDocument: '=',
        listCategories: '=',
        onFinishFunction: '&?',
        serverPath: '@',
        serverVersion: '@'
      },
      templateUrl: 'shared/tcq-bc3-form-upload/tcq-bc3-form-upload-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope, $rootScope, Notification, HelperFactory, UploadFactory, UploadFileConstants,$mdDialog,$document) {
        var vm = this;
        vm.name = 'tcqBc3FormUpload';

        vm.uploadFile       = uploadFile;
        vm.selectFile       = selectFile;
        vm.cleanForm 		= cleanForm;

        vm.size = 'min';
        vm.files = [];
        vm.errFiles = [];
        vm.errors = [];

        vm.chunkSize = angular.isUndefined($scope.chunkSize) ? UploadFileConstants.CHUNKSIZE : $scope.chunkSize;
        vm.maxSize = angular.isUndefined($scope.maxSize) ? UploadFileConstants.MAX_SIZE_UPLOAD_DEFAULT : $scope.maxSize;

        vm.onFinishFunction = $scope.onFinishFunction;

        vm.iconLeft = {};
        vm.iconLeft.class = 'material-icons';
        vm.iconLeft.content = 'cloud_download';

        /////////////////////////////////////////////////////
        //----------------- functions ---------------------//
        /////////////////////////////////////////////////////

        function selectFile() {
          //solo deberia haber un elemento en el array
        	
        	
          if (vm.files.length > 0) {
        	//   
              $mdDialog.show({
                  controller: function DialogController($scope, $mdDialog, parametres, SisquilloFactory, Notification, $translate) {
                    var vm = this;
                    vm.codi = '';
                    vm.descripcio = '';
                    vm.rotulo =0;
                    vm.errors = [];

                    $scope.accept = function() {

	                    parametres.uploadFile(parametres.file,vm.codi,vm.descripcio,vm.rotulo);  
	                	$mdDialog.hide();
                    };
                    $scope.hide = function() {
                      parametres.cleanForm();
                      $mdDialog.cancel();
                    };
                    $scope.cancel = function() {
                      parametres.cleanForm();
                      $mdDialog.cancel();
                    };
                  },
                  controllerAs: 'ctrl',
                  templateUrl: 'obres/exportar-a-fie/importar-de-fie-dialog.tpl.html',
                  parent: angular.element($document.body),
                  clickOutsideToClose:true,
                  locals: {'parametres': {cleanForm: vm.cleanForm, uploadFile: vm.uploadFile, file: vm.files[0]}} 
                });
        	  
        	  
        	  
        	  
        	//  
          }
        }

        function cleanForm() {
          vm.objFile = {};
          vm.files = [];
          vm.errFiles = [];
          vm.errors = [];
        }

        function onErrorUpload(objFile, error) {
          Notification.processMessage(error.data);
          vm.errors = Notification.processError(error.data);
          console.log('Error durant la importació.');
          $rootScope.$broadcast('$deactivateLoader');
          vm.files = [];
        }

        function onFinishUpload(objFile, response) {
          if (UploadFactory.checkFinalUpload(vm.files)) {
            cleanForm();
            if (!angular.isUndefined(vm.onFinishFunction)) {
              vm.onFinishFunction()(response.data.data.sisId);
            }
          }
          $rootScope.$broadcast('$deactivateLoader');
        }

        function uploadFile(file,codi,descripcio,rotulo) {
          vm.errors = [];
          if (angular.isDefined(file) && file !== null) {

            $rootScope.$broadcast('$activateLoader');

            vm.objFile = file;
            vm.objFile.fields = {codi:codi,descripcio:descripcio,rotulo:rotulo};
            vm.objFile.fields.newFileId = HelperFactory.uniqueMoment();
            vm.objFile.chunkSize = vm.chunkSize;
            vm.objFile.sizeUrl = 'ok.html';
            vm.objFile.baseUrl = '/api/' + $scope.serverPath;
            vm.objFile.controllerUrl = 'upload/bc3';
            vm.objFile.headers = { 'authsecurity' : $cookies.get('authsecurity'), 'x-api-version' : $scope.serverVersion };

            UploadFactory.uploadFile(vm.objFile, onFinishUpload, onErrorUpload).catch(function(error) {
              onErrorUpload(vm.objFile, error);
            });
          }
        }

        

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint 'no-unused-vars': [2, {'args': 'none'}] */
      }
    };
  }
}());