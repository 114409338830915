(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name plantillaConductiuEspai.dades.controller:ObresDadesCtrl
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres.dades')
    .controller('ObresDadesCtrl', ObresDadesCtrl);

  // $mdDialog
  function ObresDadesCtrl(SisquilloFactory, TipologiaPreguntaConstants, HelperFactory, $stateParams, obraData, $state, $mdDialog, ItecTreeTracking, $rootScope, $window, $scope, Notification, $translate, dadesGeneralsData, tipologies, ImatgeFactory, ApiLayoutConstant, $cookies, TcqihomeConstant) {
    var vm = this;
    vm.ctrlName = 'ObresDadesCtrl';


    vm.back = back;
    vm.desarObra = desarObra;
    vm.refresh = refresh;
    vm.tipologies = tipologies.data;

    vm.ApiLayoutConstant = ApiLayoutConstant;
    vm.TcqihomeConstant = TcqihomeConstant;
    vm.TipologiaPreguntaConstants = TipologiaPreguntaConstants

    vm.iconLeft = {};
    vm.iconLeft.class = 'material-icons';
    vm.iconLeft.content = 'file_upload';

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';
    vm.isNew = false;

    init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME} , $cookies.get('authsecurity')).then( function(response){
      vm.headers = {
          'x-api-version': ApiLayoutConstant.SERVER_VERSION_TCQIHOME,
          'authtcqihome': response.app.auth
      };
    });

    vm.errors = [];

    if (angular.isUndefined(obraData) || angular.isUndefined(obraData.data)) {
      HelperFactory.setParams($stateParams, {'idObra': undefined});
      vm.obra = {};
      vm.obra.monedes = [];
      vm.obra.monedes.push(dadesGeneralsData.data.monedaDefault);
      vm.isNew = true;
    } else {
      vm.obra = obraData.data.sisquillo;
      vm.obra.monedes = [];
      vm.obra.monedes.push(vm.obra.moneda);
      vm.isNew = false;
    }
    vm.maxUploadSize = dadesGeneralsData.data.maxUploadSizeImages;

    vm.formulari = {
      nom: 'dadesGenerals',
      func: function() {
        return vm.desarObra();
      },
      camps: [
          {
            type: 'text',
            name: 'codi',
            label: 'DEFAULT_CODI',
            columns: 6,
            required: true,
            disabled: vm.obra.teSeguiment,
            maxLength: 50
          },
          {
            type: 'text',
            name: 'descripcio',
            label: 'DEFAULT_TITOL_OBRA',
            columns: 6,
            required: true,
            disabled: vm.obra.teSeguiment,
            maxLength: 2000
          },
          {
            type: 'chips',
            name: 'monedes',
            label: 'DEFAULT_MONEDA',
            fieldProperty: 'descripcion',
            textPlaceholder: $translate.instant('DEFAULT_MONEDA'),
            list: dadesGeneralsData.data.monedaList,
            required: true,
            readonly: vm.obra.teSeguiment,
            columns: 6,
            mdMaxChips: 2
          },
          {
            type: 'select',
            simple: true,
            name: 'tipologia',
            label: 'DEFAULT_TIPOLOGIA',
            list: dadesGeneralsData.data.tipologiaList,
            required: true,
            disabled: vm.obra.teSeguiment,
            columns: 6
          },
          {
            type: 'select',
            simple: true,
            name: 'contractacio',
            label: 'DEFAULT_CONTRACTACIO',
            list: dadesGeneralsData.data.contractacioList,
            required: false,
            disabled: vm.obra.teSeguiment,
            columns: 6
          },
          {
            type: 'select',
            simple: true,
            name: 'actuacio',
            label: 'DEFAULT_TIPUS_ACTUACIO',
            list: dadesGeneralsData.data.actuacioList,
            required: false,
            disabled: vm.obra.teSeguiment,
            columns: 6
          },
          {
            type: 'textarea',
            name: 'notes',
            label: 'DEFAULT_NOTES',
            required: false,
            disabled: vm.obra.teSeguiment,
            columns: 12,
            maxLength: 2000
          },
          {
            type: 'site',
            name: 'site_coordinates',
            latitud: 'site_latitud',
            longitud: 'site_longitud',
            label: 'DEFAULT_LOCALITZACIO',
            required: false,
            disabled: vm.obra.teSeguiment,
            columns: 12
          }
      ]
    };

    function back() {
      $state.go('^', $stateParams, {reload: true});
    }

    function refresh() {
      $state.go('.', $stateParams, {reload: true});
    }

    function desarObra() {
      if (angular.isDefined(vm.obra.monedes[0])) {
        vm.obra.moneda = vm.obra.monedes[0];
      }
      if (vm.isNew) {
        return SisquilloFactory.add(vm.obra).then(function(response) {
          Notification.success('OBRES_CREATE_SUCCESS');
          HelperFactory.setParams($stateParams, {'idObra': response.data});
          $state.go('.', $stateParams, {'reload':true});
        }).catch(function(error) {
          vm.errors = Notification.processError(error);
        });

      } else {
        return SisquilloFactory.update(vm.obra.id, vm.obra).then(function() {
          Notification.success('OBRES_UPDATE_SUCCESS');
        }).catch(function(error) {
          vm.errors = Notification.processError(error);
        });
      }

    }

    vm.selectTipologia = function() {
      $mdDialog.show({
        locals: {obra: vm.obra, tipologies: vm.tipologies},
        controller: TipologiaDialogController,
        controllerAs: 'ctrl',
        templateUrl: 'obres/dades/tipologies.tpl.html',
        parent: angular.element(document.body),
        title:'DEFAULT_PREVENCIO_MINIMITZACIO',
        clickOutsideToClose:true,
        escapeToClose:true
      });
    }

    $scope.$on ('tipologia-new-preguntes', function (evt, list) {

      // Copiem les respostes que ja existeixen
      angular.forEach (vm.obra.listPreguntes, function (preguntaExisting){
        angular.forEach (list, function (preguntaNova){
          if (preguntaExisting.idTipologiaPregunta === preguntaNova.idTipologiaPregunta) {
            preguntaNova.valorBoolea = preguntaExisting.valorBoolea;
            preguntaNova.valorNumeric = preguntaExisting.valorNumeric;
            preguntaNova.valorCadena = preguntaExisting.valorCadena;
          }
        });

      });

      vm.obra.listPreguntes = list;
    });

    vm.getDescPregunta = function(pregunta) {
      var result = pregunta.nom;
      if ((pregunta.ua !== null) && (pregunta.ua !== '')) {
        result += ' (' + pregunta.ua + ')';
      }
      return result;
    };


    function TipologiaDialogController (obra, tipologies) {
      var ctrl = this;
      ctrl.obra = obra;
      ctrl.tipologies = [];
      ctrl.expanded = [];


      // Monta l'arbre de tipologies a partir de la llista
      ctrl.addChildren = function (parent) {

        if (parent !== undefined) {
          parent.children = [];
        }
        angular.forEach (tipologies, function (item) {
          if ((parent === undefined) && (item.idPare === null)) {
            ctrl.tipologies.push (item);
            ctrl.addChildren (item);
            // El node rel sempre surt expanded
            ctrl.expanded.push(item);
          }

          if ((parent !== undefined) && (item.idPare === parent.id)) {
            parent.children.push(item);
            ctrl.addChildren (item);
          }
        });
      };

      // fica l'item amb el id a expanded i expandeix el seu pare
      ctrl.expandItemAndParent = function (id) {
        if (id === null) return;
        angular.forEach (tipologies, function (item) {
          if (item.id === id) {
            ctrl.expanded.push(item);
            ctrl.expandItemAndParent (item.idPare);
          }
        });
      };

      // Obte l'element checkejat o null si no n'hi ha cap
      ctrl.getItemChecked = function () {
        var result = null;
        angular.forEach (tipologies, function (item) {
          if (item.checked) {
            result = item;
          }
        });
        return result;
      };

      // Busquem el item seleccionat i critem a expandir tot el seu cami
      ctrl.expandCheckedPath = function () {
        var item = ctrl.getItemChecked();
        if (item !== null) {
          ctrl.expanded = [];
          ctrl.expandItemAndParent(item.idPare);
        }
      };

      // Busqumne el node i el fiquem a checked
      ctrl.setTipologiaToChecked = function(node) {
        if ((node !== null) && (node !== undefined)) {
          angular.forEach (tipologies, function (item) {
            if (item.id == node.id) {
              item.checked = true;
            }
            else {
              item.checked = false;
            }
          });
        }
      };

      ctrl.setTipologiaToChecked (ctrl.obra.tipologiaEDC);
      ctrl.addChildren ();
      ctrl.expandCheckedPath ();

      // Quan checkejen fiquem la resta a unchecked
      ctrl.changeCheck = function(node) {
        if (node.checked) {
          ctrl.setTipologiaToChecked(node);
        }
      };

      ctrl.confirm = function() {
        var item = ctrl.getItemChecked();
        ctrl.obra.tipologiaEDC = item;
        SisquilloFactory.getSisquilloTipologies(ctrl.obra.id, item.id).then(function (response) {
          $rootScope.$broadcast('tipologia-new-preguntes', response.data);
          $mdDialog.hide();

        }).catch(function (error) {
          ctrl.errors = Notification.processError(error);
        });

      };

      ctrl.cancel = function () {
        $mdDialog.hide();
      };

    };



  }
}());
