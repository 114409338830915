(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.sisquillo.factory:SisquilloFactory
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.sisquillo')
    .factory('SisquilloFactory', SisquilloFactory);

  function SisquilloFactory(DataFactory, ApiLayoutConstant) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres';

    var base = {

      getAll: function(){
        var url = api;
        return DataFactory.get(url);
      },

      getDadesGenerals: function(){
        var url = api + '/dades_generals';
        return DataFactory.get(url);
      },

      getTipologies: function(){
        var url = api + '/tipologies/all';
        return DataFactory.get(url);
      },

      get: function(id){
        var url = api + '/' + id;
        return DataFactory.get(url);
      },

      getSisquilloTipologies: function (idSisquillo, idTipologia) {
        var url;
        if (idSisquillo !== undefined) {

          url = api + '/' + idSisquillo + '/' + idTipologia + '/preguntes';
        }
        else {
          url = api + '/' + idTipologia + '/preguntes';
        }
        return DataFactory.get(url);
      },

      update: function(id, obj){
        var url = api + '/' + id;
        return DataFactory.update(url,obj);
      },

      add: function(obj){
        var url = api;
        return DataFactory.add(url,obj);
      },

      delete: function(id){
        var url = api;
        return DataFactory.delete(url, id);
      },

      download: function(id) {
        var url = api + '/' + id +'/download';
        return DataFactory.get(url);
      }

    };
    return base;
  }
}());
