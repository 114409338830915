(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.obres.controller:ObresCtrl
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres')
    .controller('ObresCtrl', ObresCtrl);

  function ObresCtrl(SisquilloFactory, HelperFactory, ConvertFactory,FileSaver, Notification, $cookies, $http,
      $rootScope, $scope, $state, $stateParams, $window, ApiLayoutConstant, ImatgeFactory, allSisquillos,
      $mdDialog, $document, UploadFileConstants) {

    var vm = this;
    vm.ctrlName = 'ObresCtrl';

    vm.obres = allSisquillos.data;
    vm.fields = [];

    vm.viewDades = viewDades;
    vm.esborrar = esborrar;
    vm.exportarObra = exportarObra;
    vm.exportarObraFIE = exportarObraFIE;
    vm.afegir = afegir;
    vm.loadObres = loadObres;
    vm.openObra = openObra;

    vm.size = UploadFileConstants.SIZE_MINIMAL;
    vm.listMode = true;

    vm.addButton = {};
    vm.addButton.class = 'material-icons tcqi--button-icon-left';
    vm.addButton.content = 'add';

    vm.searchText = '';

    vm.sortType = '';
    vm.sortReverse  = false;

    vm.ApiLayoutConstant = ApiLayoutConstant;

    vm.tcqiApps = {
      'security': {
        'image': $rootScope.prefixItecGuicomponents +
                'images/layout/header/menu/directives/images/logos/security.png',
        'app': ApiLayoutConstant.APP_NOM_ADMINISTRACIO,
        'title': 'DEFAULT_TCQI_SECURITY',
        'descr': ''
      },
      'manteniment': {
        'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/manteniment.png',
        'app': ApiLayoutConstant.APP_NOM_MANTENIMENT,
        'title': 'DEFAULT_TCQI_MANTENIMENT',
        'descr': ''
       },
       'areacollaborativa': {
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/areaColaborativa.png',
         'app': ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA,
         'title': 'DEFAULT_TCQI_AREA_COLABORATIVA',
         'descr': ''
       },
       'comparacioofertes': {
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/comparacioOfertes.png',
         'app': ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES,
         'title': 'DEFAULT_TCQI_COMPARACIO_OFERTES',
         'descr': ''
        },
        'estimaciocostos': {
          'image': $rootScope.prefixItecGuicomponents +
                     'images/layout/header/menu/directives/images/logos/estimaciocostos.png',
          'app': ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,
          'title': 'DEFAULT_TCQI_ESTIMACIO_COSTOS',
          'descr': ''
         }
      };

    function viewDades(id) {
      if (angular.isDefined(id) && id !== null) {
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }

    function esborrar(id) {
      $rootScope.$broadcast('$activateLoader');
      SisquilloFactory.delete(id).then(function(){
        $scope.$apply(function() {
          HelperFactory.removeFromArray(vm.obres, id);
        });
        $rootScope.$broadcast('$deactivateLoader');
      }).catch(function(error){
        Notification.processError(error);
        $rootScope.$broadcast('$deactivateLoader');
      });
    }

    function exportarObra(id) {
      //aqui se exportará la obra
      var url = '/api/' + ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres/' + id + '/download';
        init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME},
            $cookies.get('authsecurity')).then( function(response){
          var headers = {};
          headers['x-api-version'] =  ApiLayoutConstant.SERVER_VERSION_TCQIHOME;
          headers['authsecurity'] = $cookies.get('authsecurity');
          headers['authtcqihome'] = response.app.auth;
          headers['responseType'] = 'arraybuffer';
          $http.get(url, {headers: headers}).then(function(response){
            var blob = ConvertFactory.b64toBlob(response.data, response.headers('Content-Type'));
            FileSaver.saveAs(blob, response.headers('filename'));
          });
        });
    }

    function exportarObraFIE(id) {
      $mdDialog.show({
        controller: function DialogController($scope, $mdDialog, SisquilloFactory, Notification, $translate) {
          var vm = this;
          vm.export_plecs = true;
          vm.export_grafics = true;
          vm.export_amid = true;
          vm.errors = [];

          $scope.accept = function() {
              //aqui se exportará la obra a FIE
              var url = '/api/' + ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres/' + id + '/downloadfie/'  +
                '?volPlecs=' + vm.export_plecs + '&volGrafics=' + vm.export_grafics +
                '&volAmids=' + vm.export_amid;

              init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME },
                  $cookies.get('authsecurity')).then( function(response) {

                  var headers = {};
                  headers['x-api-version'] =  ApiLayoutConstant.SERVER_VERSION_TCQIHOME;
                  headers['authsecurity'] = $cookies.get('authsecurity');
                  headers['authtcqihome'] = response.app.auth;
                  headers['responseType'] = 'arraybuffer';
                  $http.get(url, {headers: headers}).then(function(response){
                   $rootScope.$broadcast('$activateLoader');

                	var blob = ConvertFactory.b64toBlob(response.data, response.headers('Content-Type'));
                    FileSaver.saveAs(blob, response.headers('filename'));
                    $rootScope.$broadcast('$deactivateLoader');
                    Notification.success("DEFAULT_ACCIO_FINALITZADA_EXIT");
                  });
                });
                $mdDialog.hide();
          };
          $scope.hide = function() {
            $mdDialog.cancel();
          };
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
        },

        controllerAs: 'ctrl',
        templateUrl: 'obres/exportar-a-fie/exportar-a-fie-dialog.tpl.html',
        parent: angular.element($document.body),
        clickOutsideToClose:true
      });
    }

    function openObra(id, app) {
      if (app === ApiLayoutConstant.APP_NOM_PRESSUPOST) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_PRESSUPOST +
          '/pressupost/#/' + id + '/obra/estructura');
      } else if (app === ApiLayoutConstant.APP_NOM_QUALITAT) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_QUALITAT +
          '/qualitat/#/' + 'obra/' + id );
      } else if (app === ApiLayoutConstant.APP_NOM_MEDIAMBIENT) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_MEDIAMBIENT +
          '/mediambient/#/' + 'obres/' + id + '/obra/indicadors/informacio');
      } else if (app === ApiLayoutConstant.APP_NOM_SEGURETATISALUT) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_SEGURETATISALUT +
          '/seguretatisalut/#/' + 'obra/' + id);
      } else if (app === ApiLayoutConstant.APP_NOM_PLANIFICACIO) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_PLANIFICACIO +
           '/planificacio/#/' + 'obres/' + id + '/tasques/gantt/diagrama');
      } else if (app === ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_SEGUIMENTECONOMIC +
          '/seguimenteconomic/#/' + id + '/obra/estructura');
      } else if (app === ApiLayoutConstant.APP_NOM_MANTENIMENT) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_MANTENIMENT +
          '/manteniment');
      } else if (app === ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + 'areacolaborativa');
      } else if (app === ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + 'comparadorofertes');
      } else if (app === ApiLayoutConstant.APP_NOM_ADMINISTRACIO) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_ADMINISTRACIO +
          '/security');
      } else if (app === ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_ESTIMACIOCOSTOS +
          '/estimaciocostos');
      } else if (app === ApiLayoutConstant.APP_NOM_CONTROLCOSTOS) {
        $window.open(ApiLayoutConstant.SERVER_ROOT_PATH + ApiLayoutConstant.SERVER_VERSION_CONTROLCOSTOS +
          '/controlcostos/#/obra/' + id + '/arxiu');
      }
    }

    function afegir(){
      $window.scrollTo(0, 0);
      HelperFactory.setParams($stateParams, {'isNew': true, 'idObra': null});
      $state.go('obres.dades', $stateParams);
    }

    function loadObres() {
      angular.forEach(vm.obres, function(obra, index) {
        if (obra.imatge) {
          ImatgeFactory.download(obra.imatge.id, vm.size, obra.id).then(function(response2) {
            vm.obres[index].imatge.data = response2.data;
          });
        }
      });
    }

    vm.loadObres();
  }
}());
