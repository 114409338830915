<md-dialog  aria-label="{{'DEFAULT_EXPORTAR_FIE' | translate}}">
  <form>
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2>{{'DEFAULT_IMPORTAR_FIE' | translate}}</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="hide()">
          <md-icon aria-label="{{'DEFAULT_TANCAR' | translate}}">close</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-dialog-content>
      <div class="md-dialog-content">
        <div class="mdl-grid">

        <div class="mdl-cell mdl-cell--12-col">
          <itec-error-block errors="ctrl.errors['']"></itec-error-block>
        </div>
    
        <div class="mdl-cell mdl-cell--12-col">
        <itec-input-text
          name="codi"
          ng-model="ctrl.codi"
          label="{{'DEFAULT_CODI' | translate}}"
          ng-required="true"
	      ng-minlength="1"
	      ng-maxlength="50"
          validation-message-required="{{::'DEFAULT_VALIDATION_MESSAGE_REQUIRED'}}"
          validation-message-maxlength="{{::'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH'}}" >
        </itec-input-text>
        </div>

        <div class="mdl-cell mdl-cell--12-col">
        <itec-input-text
          name="codi"
          ng-model="ctrl.descripcio"
          label="{{'DEFAULT_DESCRIPCIO' | translate}}"
          ng-required="true"
	      ng-minlength="1"
	      ng-maxlength="2000"
          validation-message-required="{{::'DEFAULT_VALIDATION_MESSAGE_REQUIRED'}}"
          validation-message-maxlength="{{::'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH'}}" >
        </itec-input-text>
        </div>
        
      </div>
    </md-dialog-content>
    <md-dialog-actions layout="row">
      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">
       {{'DEFAULT_CANCELAR' | translate}}
      </md-button>
      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">
        {{'DEFAULT_ACCEPTAR' | translate}}
      </md-button>
    </md-dialog-actions>
  </form>
</md-dialog>