(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:theme
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('theme', 'tcqihome');
}());
