(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.imatge.factory:ImatgeFactory
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.imatge')
    .factory('ImatgeFactory', ImatgeFactory);

  function ImatgeFactory(DataFactory, ApiLayoutConstant, $http, $cookies) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'imatges';

    var base = {

      download: function(id, size, idSisquillo){
        var url = '/api/' + ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres/' + idSisquillo + '/imatges/' + id + '/download?size=' + size;

        var promise = init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME},
            $cookies.get('authsecurity')).then( function(response){
          var headers = {};
          headers['x-api-version'] = ApiLayoutConstant.SERVER_VERSION_TCQIHOME;
          headers['authsecurity'] = $cookies.get('authsecurity');
          headers['authtcqihome'] = response.app.auth;
          headers['responseType'] = 'arraybuffer';
          return $http.get(url, {headers: headers});
        });
        return promise;
      }

    };
    return base;
  }
}());
