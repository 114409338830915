(function () {
'use strict';

/**
 * @ngdoc service
 * @name itecTcqihome.api.logout.factory:LogoutFactory
 *
 * @description
 *
   */
  angular
    .module('itecTcqihome.api.logout')
    .factory('LogoutFactory', LogoutFactory);

    function LogoutFactory(ApiLayoutConstant, DataFactory) {
      var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'j_spring_security_logout';

      var LogoutBase = {

        get: function() {
          return DataFactory.get(api);
        }

      };
      return LogoutBase;
    }
}());