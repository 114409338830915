<div id="tcqi-content" class="mdl-cell--12-col tcqi--with-scroll mdl-grid mdl-grid--no-spacing">

  <!-- Botones -->
  <div id="tcqihome--obres-buttons" class="mdl-cell--12-col tcqi--margin-top-20">
    <div class="tcqihome--obres-title">
      <i class="material-icons">folder</i>
      <span class="bold">{{::'OBRES_TOTES_OBRES' | translate}}</span>
    </div>

    <button class="tcqi--margin-left-30 tcqi--margin-right-15 itec-float-right mdl-button mdl-js-button mdl-button--icon
        mdl-button--colored" ng-click="ctrl.listMode = !ctrl.listMode" ng-if="ctrl.obres.length > 0">

      <i ng-if="ctrl.listMode" class="icon-th-large" style="font-size: large;"
         title="{{::'DEFAULT_ACCIONS_VISUALITZAR_ICONES' | translate}}"
         aria-label="{{::'DEFAULT_ACCIONS_VISUALITZAR_ICONES' | translate}}">
       
      </i>
      <i ng-if="!ctrl.listMode" class="material-icons"
         title="{{::'DEFAULT_ACCIONS_VISUALITZAR_LLISTA' | translate}}"
         aria-label="{{::'DEFAULT_ACCIONS_VISUALITZAR_LLISTA' | translate}}">
        format_list_bulleted
      </i>
    </button>

    <tcq-bc3-form-upload
      select-files-text="DEFAULT_IMPORTAR_FIE"
      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
      max-size="{{ctrl.TcqiHomeConstant.UPLOAD_OBRA_MAX_SIZE}}"
      show-only-icon="false"
      on-finish-function="ctrl.viewDades"
      icon-left="ctrl.iconLeft"
      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"
      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
      class="itec-float-right">
    </tcq-bc3-form-upload>

    <tcq-obra-form-upload
      select-files-text="DEFAULT_IMPORTAR_TCQI"
      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
      max-size="10MB"
      show-only-icon="false"
      on-finish-function="ctrl.viewDades"
      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"
      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
      class="itec-float-right">
    </tcq-obra-form-upload>

    <itec-button class="itec-float-right tcqi--margin-left-20" label="DEFAULT_NOU"
                 ng-click-function="ctrl.afegir()" icon-left="ctrl.addButton">
    </itec-button>


    <form ng-if="ctrl.obres.length > 0" class="itec-float-right">
      <div ng-show="ctrl.expanded" class="tcqihome-search itec-float-right mdl-textfield mdl-js-textfield">
        <input class="mdl-textfield__input" type="text" id="search-expandable" ng-model="ctrl.searchText" />
        <label class="mdl-textfield__label" for="search-expandable">
          {{::'DEFAULT_CERCA_CODI_O_TITOL' | translate}}
        </label>
      </div>

      <label class="itec-float-right mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
             ng-click="ctrl.expanded=!ctrl.expanded">
        <i class="material-icons" title="{{::'DEFAULT_CERCAR' | translate}}"
            aria-label="{{::'DEFAULT_CERCAR' | translate}}">search</i>
      </label>
    </form>
  </div>





  <div ng-if="ctrl.obres.length === 0" id="tcqihome--obres-no-items">
    <span>{{::'DEFAULT_NO_ITEM' | translate}}</span>
    <div class="clear"></div>

    <!-- Aplicaciones "TCQi" -->
    <div class="tcqihome--obres-title"><i class="icon-icono-grups-blanc"></i><span class="bold">MÒDULS</span></div>
    <div class="tcqi-table mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqi--margin-top-20">
      <div ng-repeat="tcqiApp in ctrl.tcqiApps" class="tcqihome--obres-apps mdl-cell--2-col">
        <div>
          <a ng-click="ctrl.openObra(undefined, tcqiApp.app)">
            <img ng-src="{{tcqiApp.image}}"><br>
            <span class="itec-float-left tcqi--margin-top-10">
              {{::tcqiApp.title | translate | uppercase}}
            </span>
          </a>
        </div>

        <div class="clear"></div>
        <span>{{::tcqiApp.descr}}</span>
      </div>
    </div>
  </div>





  <!-- Listado de obras -->
  <div ng-if="ctrl.obres.length > 0" id="tcqihome--obres-list" class="tcqi-table mdl-cell--12-col">


    <!-- Cabecera -->
    <div ng-if="ctrl.listMode" class="tcqi--table-header mdl-grid mdl-grid--no-spacing">
      <div class="mdl-cell--1-col">
        <a ng-click="ctrl.sortType = 'codi'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          <span>{{::'DEFAULT_CODI' | translate}}</span>

          <i ng-show="ctrl.sortType === 'codi' && !ctrl.sortReverse" class="icon-caret-down"></i>
          <i ng-show="ctrl.sortType !== 'codi'" class="icon-caret-down tcqi-two-icon-carets"></i>

          <i ng-show="ctrl.sortType === 'codi' && ctrl.sortReverse" class="icon-caret-up"></i>
          <i ng-show="ctrl.sortType !== 'codi'" class="icon-caret-up tcqi-two-icon-carets"></i>
        </a>
      </div>

      <div class="mdl-cell--5-col">
        <a ng-click="ctrl.sortType = 'descripcio'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          <span>{{::'DEFAULT_TITOL' | translate}}</span>

          <i ng-show="ctrl.sortType === 'descripcio' && !ctrl.sortReverse" class="icon-caret-down"></i>
          <i ng-show="ctrl.sortType !== 'descripcio'" class="icon-caret-down tcqi-two-icon-carets"></i>

          <i ng-show="ctrl.sortType === 'descripcio' && ctrl.sortReverse" class="icon-caret-up"></i>
          <i ng-show="ctrl.sortType !== 'descripcio'" class="icon-caret-up tcqi-two-icon-carets"></i>
        </a>
      </div>

      <div class="mdl-cell--1-col">
        <a ng-click="ctrl.sortType = 'dataCreacio'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          {{::'DEFAULT_DATA' | translate}}

          <span ng-show="ctrl.sortType === 'dataCreacio' && !ctrl.sortReverse" class="icon-caret-down"></span>
          <span ng-show="ctrl.sortType !== 'dataCreacio'" class="icon-caret-down tcqi-two-icon-carets"></span>

          <span ng-show="ctrl.sortType === 'dataCreacio' && ctrl.sortReverse" class="icon-caret-up"></span>
          <span ng-show="ctrl.sortType !== 'dataCreacio'" class="icon-caret-up tcqi-two-icon-carets"></span>
        </a>
      </div>

      <div class="mdl-cell--3-col"><span>{{::'DEFAULT_APLICACIONS' | translate}}</span></div>
      <div class="mdl-cell--2-col"><span>{{::'DEFAULT_ACCIONS' | translate}}</span></div>
    </div>


    <!-- Ítems. Formato estándar -->
    <div ng-if="ctrl.listMode" class="tcqi--table-tbody">
      <div ng-repeat="obra in ctrl.obres | obraByCodiAndDescripcio:ctrl.searchText |
                      orderBy:ctrl.sortType:ctrl.sortReverse" class="mdl-grid mdl-grid--no-spacing">

        <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"
            ng-click="ctrl.viewDades(obra.id)" title="{{::obra.codi}}">
          <span class="tcqi--padding-left-15">{{::obra.codi}}</span>
        </div>

        <div class="mdl-cell--5-col tcqi--ellipsis" title="{{::obra.descripcio}}"
            ng-click="ctrl.viewDades(obra.id)">
          <span class="tcqi--padding-left-15">{{::obra.descripcio}}</span>
        </div>

        <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha" ng-click="ctrl.viewDades(obra.id)">
          <span class="tcqi--padding-left-15">{{::obra.dataCreacio | date: 'itecDate'}}</span>
        </div>

        <div class="mdl-cell--3-col tcqihome--obres-table-tbody-td-apps">
          <div class="tcqi--padding-left-15">
          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pre-color"
                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PRESSUPOST)"
                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces">
            <i class="icon-tcqi-icona-pre"></i>
          </button>

          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gcq-color"
                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_QUALITAT)"
                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces">
            <i class="icon-tcqi-icona-gcq"></i>
          </button>

          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-seg-color"
                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGURETATISALUT)"
                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces">
            <i class="icon-tcqi-icona-ess"></i>
          </button>

          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"
                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT)"
                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces ">
            <i class="icon-tcqi-icona-gma"></i>
          </button>

          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pla-color"
                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PLANIFICACIO)"
                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces">
            <i class="icon-tcqi-icona-pla"></i>
          </button>


          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-seg-color"
                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC)"
                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces">
            <i class="icon-tcqi-icona-seg"></i>
          </button>

          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-cdc-color"
                  ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_CONTROLCOSTOS)"
                  ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces">
            <i class="icon-tcqi-icona-gcc"></i>
          </button>
          </div>
        </div>

        <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-acciones">
          <div class="tcqi--padding-left-15">
          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
              title="{{'DEFAULT_DADES_GENERALS' | translate}}"
              ng-click="ctrl.viewDades(obra.id)">
            <i class="material-icons">description</i>
          </button>

          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                  title="{{'DEFAULT_ESBORRAR_OBRA' | translate}}"
                  itec-dialog-confirm
                  itec-dialog-confirm-title="OBRES_DELETE_TITLE"
                  itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT"
                  itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"
                  itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"
                  itec-dialog-confirm-function-ok="ctrl.esborrar(obra.id)">
            <i class="material-icons">delete_forever</i>
          </button>

          <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                  title="{{'DEFAULT_EXPORTAR' | translate}}"
                  ng-click="ctrl.exportarObra(obra.id)">
            <i class="icon-home-export-tcqi"></i>
          </button>

          <button id="fie" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                  title="{{'DEFAULT_EXPORTAR_FIE' | translate}}"
                  ng-click="ctrl.exportarObraFIE(obra.id)">
             <!--<i class="icon-android-upload"></i>-->
             <i class="icon-home-export-fie"></i>
          </button>
          </div>
        </div>
      </div>
    </div>


    <!-- Ítems. Formato cuadrícula -->
    <div ng-if="!ctrl.listMode" class="mdl-grid mdl-grid--no-spacing">
      <div ng-repeat="obra in ctrl.obres | obraByCodiAndDescripcio:ctrl.searchText"
          class="mdl-cell mdl-cell--3-col tcqihome-gallery-container">
        <div class="tcqihome-gallery-element">
          <div class="tcqihome-gallery-imatge">
            <span class="tcqihome-gallery-imatge-helper"></span><img ng-if="obra.imatge"
              data-ng-src="data:image/png;base64,{{obra.imatge.data}}" ng-click="ctrl.viewDades(obra.id)">

            <img ng-if="!obra.imatge" class="ng-thumb"
                 ng-src="{{$root.prefixItecGuicomponents}}images/common-controls/views/images/icoimg.gif"
                 ng-click="ctrl.viewDades(obra.id)">
          </div>

          <div><span>{{obra.descripcio}}</span></div>
        </div>
      </div>
    </div>
  </div>
</div>
