(function () {
  'use strict';

  angular
    .module('itecTcqihome.obres')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('obres', {
        url: '/obres',
        views: {          
          'wrapper': {
            templateUrl: 'obres/obres.tpl.html',
            controller: 'ObresCtrl',
            controllerAs: 'ctrl',
            resolve: {
              allSisquillos: function(SisquilloFactory, ApiLayoutConstant, PermisosFactory, $rootScope) {
                if (angular.isUndefined($rootScope.acces) || $rootScope.acces === null ) {
                  PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(
                    function(response) {
                      $scope.$apply(function() {
                        $rootScope.acces = response.data.acces;
                      });
                    }).catch(function(){});
                }
                return SisquilloFactory.getAll().catch(function(){});
              }
            }
          }
        }
      });
  }
}());